import { VFC } from "react";
import { Box, Typography, Stack } from "@mui/material";
import styled from "styled-components";

export const PageTitle: VFC = () => {
  return (
    <Box style={{ borderBottom: "solid 1px #ddd", width: "100%" }}>
      <Stack direction="row" justifyContent="flex-start" alignItems={"center"}>
        <Typography sx={{ fontSize: "16px", padding: "0 24px" }} color="black">
          ネットワーク接続
        </Typography>
        <PageTitleButton
          style={{
            color: "black",
            borderColor: "#00A0E9",
          }}
        >
          接続設定
        </PageTitleButton>
        <PageTitleButton
          style={{
            color: "grey",
            borderColor: "transparent",
          }}
        >
          測定
        </PageTitleButton>
      </Stack>
    </Box>
  );
};

const PageTitleButton = styled.button`
  padding: 12px 16px;
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  border-bottom: solid 2px transparent;
  background-color: transparent;
`;
