import { useState } from "react";

export const useSpeedTest = (): {
  downloadSpeed: string;
  setDownloadTime: () => void;
} => {
  const [downloadSpeed, setDownloadSpeed] = useState<string>("");

  const setDownloadTime = () => {
    // TODO : 10秒間で1秒間隔で実行してその中央値をとるほうがいいかも
    // 回線速度を計算
    setDownloadSpeed("...計測中です");
    let megaBpsList: number[] = [];
    for (let i = 0; i < 10; i++) {
      const imageBytes = 102117; // 画像のサイズ（Bytes）
      const imageBits = imageBytes * 8; // 画像のサイズ（Bits）
      let startTime: number = new Date().getTime(); // 読み込みの開始時間
      let endTime: number | null = null;
      let image: HTMLImageElement = new Image();
      image.onload = () => {
        endTime = new Date().getTime(); // 読み込みの完了時間
        const diffSeconds: number = (endTime - startTime) / 1000; // 画像の読み込みに何秒かかったか
        const megaBps: number = parseFloat(
          (imageBits / diffSeconds / (1024 * 1024)).toFixed(2)
        );
        megaBpsList.push(megaBps);
        if (megaBpsList.length > 0 && megaBpsList.length % 10 === 0) {
          setDownloadSpeed(calculateMedian(megaBpsList).toString());
        }
      };
      image.src = `${process.env.PUBLIC_URL}/download_img.jpg?+ ${startTime}`;
    }
  };

  const calculateMedian = (dataList: number[]) => {
    //平均の計算
    if (dataList.length === 0) {
      return 0;
    }

    dataList.sort((a, b) => {
      return a - b;
    });

    const half = Math.floor(dataList.length / 2);

    if (dataList.length % 2) {
      return dataList[half];
    } else {
      return (dataList[half - 1] + dataList[half]) / 2;
    }
  };

  return { downloadSpeed, setDownloadTime };
};
