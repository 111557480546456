import { useEffect, RefObject, useState } from "react";
import { MediaConnection } from "skyway-js";
import { useDevices } from "src/hooks/useDevices";
import { usePeer } from "src/hooks/usePeer";
import { useMediaStream } from "./useMediaStream";
import { DeviceType } from "src/types/device";
import { LogFuncType } from "src/types/networkLog";
import { useMediaConnection } from "src/hooks/useMediaConnection";
import { useQueryString } from "src/hooks/useQueryString";
import { usePeerEvent } from "src/hooks/usePeerEvent";
import { MainModal } from "src/layout/Main/Modal/const";
import { useNetworkLog } from "src/hooks/useNetworkLog";

export const useWebRtc = (
  videoRef: RefObject<HTMLVideoElement>,
  anotherVideoRef: RefObject<HTMLVideoElement>,
  connectOpen: MainModal.ModalConnectStatus,
  setConnectOpen: (status: MainModal.ModalConnectStatus) => void
): {
  audioInputs: DeviceType[];
  videoInputs: DeviceType[];
  setAudioInputId: (audioId: string | undefined) => void;
  setVideoInputId: (videoId: string | undefined) => void;
  audioInputId: string | undefined;
  videoInputId: string | undefined;
  viewAudioDeviceId: string | undefined;
  viewVideoDeviceId: string | undefined;
  peerId: string;
  anotherPeerId: string | null;
  connectStatus: boolean;
  connectPeer: (peerId: string) => boolean;
  mediaConnection: MediaConnection | undefined;
  getStatus: LogFuncType;
} => {
  const [connectStatus, setConnectStatus] = useState<boolean>(false);
  const [anotherPeerId, setAnotherPeerId] = useState<string | null>(null);
  const {
    audioInputs,
    videoInputs,
    setAudioInputId,
    setVideoInputId,
    audioInputId,
    videoInputId,
    viewAudioDeviceId,
    viewVideoDeviceId,
    setViewAudioDeviceId,
    setViewVideoDeviceId,
  } = useDevices();
  const remotePeerId = useQueryString("peer");
  if (remotePeerId && remotePeerId !== anotherPeerId) {
    setAnotherPeerId(remotePeerId);
  }
  const turnFlg = useQueryString("turn") === "on";
  const peer = usePeer(turnFlg);
  const { mediaStream } = useMediaStream(
    audioInputs,
    videoInputs,
    audioInputId,
    videoInputId,
    setViewAudioDeviceId,
    setViewVideoDeviceId
  );
  const { connectPeer, mediaConnection, setMediaConnection } =
    useMediaConnection(
      peer,
      mediaStream,
      anotherPeerId,
      connectStatus,
      setConnectStatus,
      setConnectOpen
    );

  const { anotherMediaStream, setAnotherMediaStream } = usePeerEvent(
    peer,
    mediaStream,
    setConnectStatus,
    setMediaConnection
  );

  const { getStatus } = useNetworkLog(mediaConnection);

  useEffect(() => {
    if (mediaConnection) {
      mediaConnection.on("stream", async (stream: any) => {
        console.warn("another stream", stream);
        setAnotherMediaStream(stream);
        setAnotherPeerId(mediaConnection.remoteId);
        setConnectStatus(true);
        setConnectOpen(MainModal.ModalConnectStatus.MODAL_CONNECT_SUCCESS);
      });
      mediaConnection.on("close", () => {
        console.warn("通信が切断しました");
        setConnectOpen(MainModal.ModalConnectStatus.MODAL_CONNECT_CLOSE);
        setConnectStatus(false);
        setAnotherPeerId(null);
        setMediaConnection(undefined);
        setAnotherMediaStream(undefined);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [mediaConnection]);

  useEffect(() => {
    if (anotherVideoRef.current) {
      if (anotherMediaStream) {
        console.warn("remote stream start");
        anotherVideoRef.current.srcObject = anotherMediaStream;
        anotherVideoRef.current.play();
      } else {
        anotherVideoRef.current.srcObject = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [anotherMediaStream]);

  useEffect(() => {
    if (videoRef.current && mediaStream) {
      videoRef.current.srcObject = mediaStream;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [mediaStream]);

  return {
    audioInputs,
    videoInputs,
    setAudioInputId,
    setVideoInputId,
    audioInputId,
    videoInputId,
    viewAudioDeviceId,
    viewVideoDeviceId,
    peerId: peer ? peer.id : "",
    anotherPeerId,
    connectStatus,
    connectPeer,
    mediaConnection,
    getStatus,
  };
};
