export const alertNotification = (title: string) => {
  if (!("Notification" in window)) {
    alert(title);
  } else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(title);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(title);
      }
    });
  }
};
