import Peer from "skyway-js";
import { useState } from "react";

export const usePeer = (turnFlg: boolean) => {
  const [peer, setPeer] = useState<Peer | null>(null);

  if (!peer) {
    if (process.env.REACT_APP_SKYWAY_KEY) {
      console.log(`turn ${turnFlg ? "on" :  "off"}`)
      const skywayKey = process.env.REACT_APP_SKYWAY_KEY;
      setPeer(new Peer({ key: skywayKey, turn: turnFlg }));
    }
  }

  return peer;
};
