import { VFC, useEffect, useState } from "react";
import { MediaConnection } from "skyway-js";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
// import { useNetworkLog } from "src/hooks/useNetworkLog";
import { now, formatToString } from "src/utils/day";
import { NetworkLogType, LogFuncType } from "src/types/networkLog";
import { useCsv } from "src/hooks/useCsv";

export const LogBoard: VFC<{
  mediaConnection: MediaConnection | undefined;
  getStatus: LogFuncType;
}> = ({
  mediaConnection,
  getStatus,
}: {
  mediaConnection: MediaConnection | undefined;
  getStatus: LogFuncType;
}) => {
  const [logStatusList, setLogStatusList] = useState<NetworkLogType[]>([]);
  const [, setRender] = useState<number>(0);
  const { handleLogDLcsv } = useCsv();

  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  useEffect(() => {
    if (mediaConnection) {
      const interval = setInterval(async () => {
        const status: any = await getStatus();
        console.warn("status", status);
        if (!status?.receive) return
          logStatusList.unshift({
            time: formatToString(now()),
            receiveVoiceByte:
              status.receive.audio && status.receive.audio.bitrate
                ? status.receive.audio.bitrate
                : 0,
            receiveJitterBufferDelay:
              status.receive.audio && status.receive.audio.jitterBufferDelay
                ? status.receive.audio.jitterBufferDelay
                : 0,
            receiveVideoByte:
              status.receive.video && status.receive.video.bitrate
                ? status.receive.video.bitrate
                : 0,
            sendVoiceByte:
              status.send.audio && status.send.audio.bitrate
                ? status.send.audio.bitrate
                : 0,
            sendJitterBufferDelay:
              status.send.audio && status.send.audio.jitterBufferDelay
                ? status.send.audio.jitterBufferDelay
                : 0,
            sendVideoByte:
              status.send.video && status.send.video.bitrate
                ? status.send.video.bitrate
                : 0,
          });
        setLogStatusList(logStatusList);
        setRender(getRandomInt(1000));
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [mediaConnection]);

  const headCells = [
    {
      id: "time",
      align: "right",
      disablePadding: false,
      label: "時間",
    },
    {
      id: "receiveVoiceByte",
      align: "right",
      disablePadding: true,
      label: "受信KB(音声)",
    },
    {
      id: "receiveJitterBufferDelay",
      align: "right",
      disablePadding: false,
      label: "受信jitterBufferDelay(音声)",
    },
    {
      id: "receiveVideoByte",
      align: "right",
      disablePadding: false,
      label: "受信KB(映像)",
    },
    // {
    //   id: "sendVoiceByte",
    //   align: "right",
    //   disablePadding: true,
    //   label: "送信KB(音声)",
    // },
    {
      id: "sendJitterBufferDelay",
      align: "right",
      disablePadding: false,
      label: "送信jitter(音声)",
    },
    // {
    //   id: "sendVideoByte",
    //   align: "right",
    //   disablePadding: false,
    //   label: "送信KB(映像)",
    // },
  ];
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: 0,
        }}
        title={
          <Grid
            container
            justifyContent="space-between"
            sx={{ height: "36px" }}
          >
            <Typography sx={{ fontSize: "14px" }}>{"ログ"}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: "30px" }}
              onClick={() => handleLogDLcsv(logStatusList)}
            >
              CSVダウンロード
            </Button>
          </Grid>
        }
      />
      <CardContent>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            maxHeight: "330px",
            "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            sx={{
              "& .MuiTableCell-root:first-of-type": {
                pl: 2,
              },
              "& .MuiTableCell-root:last-child": {
                pr: 3,
              },
            }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logStatusList.map((logStatus: any, index: number) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left">
                    {logStatus.time}
                  </TableCell>
                  <TableCell align="right">
                    {logStatus.receiveVoiceByte / 1000}
                  </TableCell>
                  <TableCell align="right">
                    {logStatus.receiveJitterBufferDelay}
                  </TableCell>
                  <TableCell align="right">
                    {logStatus.receiveVideoByte / 1000}
                  </TableCell>
                  {/* <TableCell align="right">
                    {logStatus.sendVoiceByte / 1000}
                  </TableCell> */}
                  <TableCell align="right">
                    {logStatus.sendJitterBufferDelay}
                  </TableCell>
                  {/* <TableCell align="right">
                    {logStatus.sendVideoByte / 1000}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
