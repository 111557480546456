// source: messaging/model/v1/shop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.messaging.model.v1.ReceptionShop', null, global);
goog.exportSymbol('proto.messaging.model.v1.Shop', null, global);
goog.exportSymbol('proto.messaging.model.v1.Shop.Detection', null, global);
goog.exportSymbol('proto.messaging.model.v1.Shop.Notification', null, global);
goog.exportSymbol('proto.messaging.model.v1.Shop.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.Shop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.Shop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.Shop.displayName = 'proto.messaging.model.v1.Shop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ReceptionShop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.ReceptionShop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ReceptionShop.displayName = 'proto.messaging.model.v1.ReceptionShop';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.Shop.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.Shop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.Shop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.Shop.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    receptionStaffName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    peerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    robotId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    robotNo: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ruraOsVersion: jspb.Message.getFieldWithDefault(msg, 8, ""),
    detection: jspb.Message.getFieldWithDefault(msg, 9, 0),
    notification: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.Shop}
 */
proto.messaging.model.v1.Shop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.Shop;
  return proto.messaging.model.v1.Shop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.Shop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.Shop}
 */
proto.messaging.model.v1.Shop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.messaging.model.v1.Shop.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptionStaffName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRobotNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuraOsVersion(value);
      break;
    case 9:
      var value = /** @type {!proto.messaging.model.v1.Shop.Detection} */ (reader.readEnum());
      msg.setDetection(value);
      break;
    case 10:
      var value = /** @type {!proto.messaging.model.v1.Shop.Notification} */ (reader.readEnum());
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.Shop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.Shop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.Shop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.Shop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.messaging.model.v1.Shop.Status} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDetection();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getNotification();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.messaging.model.v1.Shop.Status = {
  STATUS_UNSPECIFIED: 0,
  STATUS_DISCONNECTED: 1,
  STATUS_MONITORING: 2,
  STATUS_DEALING_WITH_CUSTOMER: 3,
  STATUS_SCREEN_SAVER: 4,
  STATUS_REBOOTING: 5,
  STATUS_COLD_REBOOTING: 6
};

/**
 * @enum {number}
 */
proto.messaging.model.v1.Shop.Detection = {
  DETECTION_UNSPECIFIED: 0,
  DETECTION_DISABLED: 1,
  DETECTION_ENABLED: 2
};

/**
 * @enum {number}
 */
proto.messaging.model.v1.Shop.Notification = {
  NOTIFICATION_UNSPECIFIED: 0,
  NOTIFICATION_DISABLED: 1,
  NOTIFICATION_ENABLED: 2
};

/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setPcId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearPcId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasPcId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {!proto.messaging.model.v1.Shop.Status}
 */
proto.messaging.model.v1.Shop.prototype.getStatus = function() {
  return /** @type {!proto.messaging.model.v1.Shop.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.messaging.model.v1.Shop.Status} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string reception_staff_name = 4;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getReceptionStaffName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setReceptionStaffName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearReceptionStaffName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasReceptionStaffName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string peer_id = 5;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getPeerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setPeerId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearPeerId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasPeerId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string robot_id = 6;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getRobotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setRobotId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearRobotId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasRobotId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 robot_no = 7;
 * @return {number}
 */
proto.messaging.model.v1.Shop.prototype.getRobotNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setRobotNo = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearRobotNo = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasRobotNo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string rura_os_version = 8;
 * @return {string}
 */
proto.messaging.model.v1.Shop.prototype.getRuraOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setRuraOsVersion = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.clearRuraOsVersion = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Shop.prototype.hasRuraOsVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Detection detection = 9;
 * @return {!proto.messaging.model.v1.Shop.Detection}
 */
proto.messaging.model.v1.Shop.prototype.getDetection = function() {
  return /** @type {!proto.messaging.model.v1.Shop.Detection} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.messaging.model.v1.Shop.Detection} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setDetection = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Notification notification = 10;
 * @return {!proto.messaging.model.v1.Shop.Notification}
 */
proto.messaging.model.v1.Shop.prototype.getNotification = function() {
  return /** @type {!proto.messaging.model.v1.Shop.Notification} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.messaging.model.v1.Shop.Notification} value
 * @return {!proto.messaging.model.v1.Shop} returns this
 */
proto.messaging.model.v1.Shop.prototype.setNotification = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ReceptionShop.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ReceptionShop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ReceptionShop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ReceptionShop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ReceptionShop}
 */
proto.messaging.model.v1.ReceptionShop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ReceptionShop;
  return proto.messaging.model.v1.ReceptionShop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ReceptionShop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ReceptionShop}
 */
proto.messaging.model.v1.ReceptionShop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ReceptionShop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ReceptionShop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ReceptionShop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ReceptionShop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.messaging.model.v1.ReceptionShop.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ReceptionShop} returns this
 */
proto.messaging.model.v1.ReceptionShop.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.messaging.model.v1.ReceptionShop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ReceptionShop} returns this
 */
proto.messaging.model.v1.ReceptionShop.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.ReceptionShop} returns this
 */
proto.messaging.model.v1.ReceptionShop.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.ReceptionShop.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.messaging.model.v1);
