export namespace MainModal {
  export enum ModalConnectStatus {
    MODAL_CLOSE = 0,
    MODAL_CONNECT_SUCCESS = 1,
    MODAL_BE_CONNECTED_SUCCESS = 2,
    MODAL_CONNECT_ERROR = 3,
    MODAL_NETWORK_ERROR = 4,
    MODAL_CONNECT_CLOSE = 5,
  }
}

export const ModalData = {
  0: {
    title: undefined,
    text: undefined,
    button: undefined,
    iconStatus: false,
  },
  1: {
    title: "接続を確認しました",
    text: undefined,
    button: "測定に進む",
    iconStatus: true,
  },
  2: {
    title: "あなたのIDへの接続を確認しました",
    text: undefined,
    button: "測定に進む",
    iconStatus: true,
  },
  3: {
    title: "接続できませんでした",
    text: "接続設定を確認し、再度接続してください。",
    button: undefined,
    iconStatus: false,
  },
  4: {
    title: "接続できませんでした",
    text: "ネットが切断されているかIDが誤っています。",
    button: undefined,
    iconStatus: false,
  },
  5: {
    title: "切断しました",
    text: "接続先から切断されました",
    button: undefined,
    iconStatus: false,
  },
};
