import { VFC } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { MainModal, ModalData } from "src/layout/Main/Modal/const";

export const ConnectModal: VFC<{
  open: MainModal.ModalConnectStatus;
  setOpen: (flg: MainModal.ModalConnectStatus) => void;
}> = ({
  open,
  setOpen,
}: {
  open: MainModal.ModalConnectStatus;
  setOpen: (flg: MainModal.ModalConnectStatus) => void;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: ModalData[open].iconStatus ? "#F6FFED" : "#FFF1F0",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };
  return (
    <Modal
      open={open > 0}
      onClose={() => {
        setOpen(0);
      }}
    >
      {ModalData[open] && (
        <Box sx={style}>
          <IconButton
            onClick={() => {
              setOpen(0);
            }}
            size="small"
            sx={{
              fontSize: "0.875rem",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseOutlined />
          </IconButton>

          <Grid justifyContent="space-between">
            <Box sx={{ width: 32, display: "inline-block" }}>
              {ModalData[open].iconStatus ? (
                <CheckCircleFilled
                  style={{
                    color: "#52C41A",
                    fontSize: "32px",
                    marginRight: "20px",
                  }}
                />
              ) : (
                <CloseCircleFilled
                  style={{
                    color: "#EB1438",
                    fontSize: "32px",
                    marginRight: "20px",
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                width: 300,
                height: "32px",
                display: "inline-block",
                verticalAlign: "bottom",
              }}
            >
              <span style={{ fontSize: "14px" }}>{ModalData[open].title}</span>
            </Box>
          </Grid>
          <Typography sx={{ mt: "14px", fontSize: "14px" }}>
            {ModalData[open].text}
          </Typography>
          {ModalData[open].button && (
            <Button
              onClick={() => {
                setOpen(0);
              }}
              variant="contained"
              color="primary"
              sx={{ mt: "32px" }}
            >
              {ModalData[open].button}
            </Button>
          )}
        </Box>
      )}
    </Modal>
  );
};
