import { VFC, useEffect, useState } from "react";
import { Typography, Card, CardContent, CardHeader } from "@mui/material";
import { MediaConnection } from "skyway-js";
import { useCheckTurnUsed } from "src/hooks/useCheckTurnUsed";
import styled from "styled-components";

export const NetworkInfo: VFC<{
  mediaConnection: MediaConnection | undefined;
}> = ({
  mediaConnection,
}: {
  mediaConnection: MediaConnection | undefined;
}) => {
  const { checkTurnUsed } = useCheckTurnUsed(mediaConnection);
  const [turnText, setTurn] = useState<string>("");

  useEffect(() => {
    setTimeout(async () => {
      const turn = await checkTurnUsed();
      console.warn("turn", turn);
      setTurn(turn ? "使用中" : "未使用");
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [mediaConnection]);

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        title={
          <Typography sx={{ fontSize: "14px" }}>
            {"ネットワーク状況"}
          </Typography>
        }
      />
      <CardContent style={{ padding: "0 16px" }}>
        {/* <Box
            height="40px"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <Typography sx={{ fontSize: "14px" }}>推奨環境度</Typography>
          </Box> */}
        <FlexListContent>
          <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
            TURNサーバーの使用: {turnText}
          </Typography>
        </FlexListContent>
        {/* <Box>
            <Typography sx={{ fontSize: "14px" }}>
              シグナリングサーバーを確認してください。
              「成功」した場合別タブで「success」のログが出力されます。
            </Typography>
            <Button color="primary">シグナリングサーバーを確認</Button>
            <Typography>
              <input width="15px" type="checkbox" />
              <span style={{ marginLeft: "10px" }}>成功</span>
              <input width="15px" type="checkbox" />
              <span style={{ marginLeft: "10px" }}>失敗</span>
            </Typography>
          </Box> */}
      </CardContent>
    </Card>
  );
};

const FlexListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;
