import { VFC, useState, useEffect } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "src/store/reducers/menu";
import { Header } from "src/layout/Header";
import { MainDrawer } from "src/layout/Drawer";
import { PageTitle } from "src/layout/Domain/PageTitle";
import { DomainHttpCheck } from "src/layout/Domain/DomainHttpCheck";
import { DomainBrowserCheck } from "src/layout/Domain/DomainBrowserCheck";
import { requestUrls } from "src/const";
import { RequestUrl } from "src/interface/RequestUrl";
import { useQueryString } from "src/hooks/useQueryString";
import { useRequest } from "src/hooks/Domain/useRequest";
import { usePopup } from "src/hooks/Domain/usePopup";
import { useCsv } from "src/hooks/useCsv";
import { useSubscribe } from "src/hooks/useSubcribe";
import { useCheckS3 } from "src/hooks/useCheckS3";
import styled from "styled-components";
import { BrowserAlert } from "src/layout/Components/BrowserAlert";

export const Domain: VFC = () => {
  const { drawerOpen } = useSelector((state: any) => state.menu);
  const [open, setOpen] = useState<any>(drawerOpen);
  const [isChrome, setChrome] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  const pcType = useQueryString("pc_type");
  if (pcType !== "worker" && pcType !== "shop") {
    // アラート
  }
  const urls = requestUrls.filter(
    (requestUrlData: RequestUrl) => requestUrlData.type === pcType
  );

  const checkBrowser = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("chrome") !== -1) {
      setChrome(true);
      console.log("ブラウザはchromeです。");
    } else if (agent.indexOf("safari") !== -1) {
      setChrome(false);
      console.log("ブラウザはsafariです。");
    }
  };

  const { domainUrls, requestDomains } = useRequest(urls);
  const { popupUrls, openPopup, updateCheck } = usePopup(urls);
  const { handleDLcsv } = useCsv();

  const [emmaStatus, setEmmaStatus] = useState<-1 | 0 | 1>(0); // -1: 失敗/0: 接続中/ 1: 成功
  const [storageStatus, setStorageStatus] = useState<-1 | 0 | 1>(0); // -1: 失敗/0: 接続中/ 1: 成功

  const { startSubScribe } = useSubscribe(setEmmaStatus);
  const { startCheckStorage } = useCheckS3(setStorageStatus);

  useEffect(() => {
    checkBrowser();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <BrowserAlert active={false} />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <MainDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box sx={{ padding: "64px 0", width: "100%" }}>
        <PageTitle pcType={pcType} />

        <Box
          sx={{ padding: "32px 24px", maxWidth: "1300px", margin: "0 auto" }}
        >
          <Grid
            container
            justifyContent="flex-end"
            style={{ margin: "0 0 24px" }}
          >
            <div>
              {pcType === "worker" && (
                <span style={{ color: isChrome ? "black" : "red" }}>
                  {isChrome
                    ? "ブラウザはchromeです"
                    : "ブラウザはchromeを使用してください"}
                </span>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "30px", ml: "10px", backgroundColor: "#00A0E9" }}
                onClick={() => {
                  const domains = domainUrls.concat(popupUrls);
                  domains.push({
                    url: "https://emma-prod.rura-emma.tokyo",
                    type: pcType === "worker" ? "worker" : "shop",
                    status:
                      emmaStatus === -1
                        ? "接続失敗"
                        : emmaStatus === 0
                        ? "未確認"
                        : "接続完了",
                    title: "RURA 接客状態管理(EMMA)",
                    detail: "",
                    checkType: "grpc",
                  });
                  domains.push({
                    url: "https://rura-storage-product.s3.amazonaws.com/",
                    type: pcType === "worker" ? "worker" : "shop",
                    status:
                      storageStatus === -1
                        ? "接続失敗"
                        : storageStatus === 0
                        ? "未確認"
                        : "接続完了",
                    title: "RURA メディアストレージ",
                    detail: "",
                    checkType: "fetch",
                  });
                  handleDLcsv(domains, isChrome);
                }}
              >
                CSVダウンロード
              </Button>
            </div>
          </Grid>
          <Typography style={{ fontSize: "18px", margin: "0 0 16px" }}>
            {pcType === "shop"
              ? "RURA用PCのドメイン接続"
              : "遠隔スタッフ用PCのドメイン接続"}
          </Typography>
          <FlexBox>
            <FlexBoxHalf>
              <DomainHttpCheck
                urls={domainUrls}
                requestDomains={requestDomains}
                emmaStatus={emmaStatus}
                storageStatus={storageStatus}
                startSubScribe={startSubScribe}
                startCheckStorage={startCheckStorage}
                pcType={pcType}
              />
            </FlexBoxHalf>
            <FlexBoxHalf>
              <DomainBrowserCheck
                urls={popupUrls}
                openPopup={openPopup}
                updateCheck={updateCheck}
                pcType={pcType}
              />
            </FlexBoxHalf>
          </FlexBox>
        </Box>
      </Box>
    </Box>
  );
};

const FlexBox = styled.div`
  display: flex;
  @media (max-width: 860px) {
    display: block;
  }
`;
const FlexBoxHalf = styled.div`
  width: calc(50% - 12px);
  margin: 0 0 0 24px;
  &:first-of-type {
    margin: 0;
  }
  @media (max-width: 860px) {
    width: 100%;
    margin: 16px 0 0;
    &:first-of-type {
      margin: 0;
    }
  }
`;
