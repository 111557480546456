import { useEffect, useState } from "react";
import { DeviceType } from "src/types/device";

export const useDevices = (): {
  audioInputs: DeviceType[];
  videoInputs: DeviceType[];
  audioInputId: string | undefined;
  setAudioInputId: (audio: string | undefined) => void;
  videoInputId: string | undefined;
  setVideoInputId: (video: string | undefined) => void;
  viewAudioDeviceId: string | undefined;
  setViewAudioDeviceId: (audio: string | undefined) => void;
  viewVideoDeviceId: string | undefined;
  setViewVideoDeviceId: (video: string | undefined) => void;
} => {
  const [audioInputs, setAudioInputs] = useState<DeviceType[]>([]);
  const [videoInputs, setVideoInputs] = useState<DeviceType[]>([]);
  const [audioInputId, setAudioInputId] = useState<string | undefined>(
    undefined
  );
  const [videoInputId, setVideoInputId] = useState<string | undefined>(
    undefined
  );
  const [viewAudioDeviceId, setViewAudioDeviceId] = useState<
    string | undefined
  >(undefined);
  const [viewVideoDeviceId, setViewVideoDeviceId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setDevices();
  }, []);

  const setDevices = () => {
    const audioDevices: any[] = [];
    const videoDevices: any[] = [];
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((obj) => {
          if (obj.kind === "audioinput") {
            audioDevices.push(obj);
          } else if (obj.kind === "videoinput") {
            videoDevices.push(obj);
          }
        });
        setAudioInputs(audioDevices);
        setVideoInputs(videoDevices);
      })
      .catch(() => {
        return alert("カメラが認識できません。\nカメラ接続をご確認ください。");
      });
  };

  return {
    audioInputs,
    videoInputs,
    audioInputId,
    setAudioInputId,
    videoInputId,
    setVideoInputId,
    viewAudioDeviceId,
    setViewAudioDeviceId,
    viewVideoDeviceId,
    setViewVideoDeviceId,
  };
};
