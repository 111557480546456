import { VFC } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { useSpeedTest } from "src/hooks/useSpeedTest";
import styled from "styled-components";

export const SpeedTest: VFC = () => {
  const { downloadSpeed, setDownloadTime } = useSpeedTest();

  return (
    <Card sx={{ width: "100%", marginTop: "18px" }}>
      <CardHeader
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        title={
          <Grid container justifyContent="space-between">
            <Typography sx={{ fontSize: "14px", mt: "10px" }}>
              {"スピードテスト"}
            </Typography>
            <Button
              onClick={setDownloadTime}
              variant="contained"
              color="primary"
            >
              テスト開始
            </Button>
          </Grid>
        }
      />
      <CardContent style={{ padding: "0 16px" }}>
        <FlexListContent>
          <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
            ダウンロード（下り）
            {downloadSpeed ? `${downloadSpeed}Mbps` : ""}
          </Typography>
        </FlexListContent>
        {/* <Box
            height="40px"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <Typography sx={{ fontSize: "14px", mt: "20px" }}>
              アップロード（上り）
            </Typography>
          </Box> */}
      </CardContent>
    </Card>
  );
};

const FlexListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;
