import { useEffect, useState } from "react";
import { DeviceType } from "src/types/device";

export const useMediaStream = (
  audioInputs: DeviceType[] | undefined,
  videoInputs: DeviceType[] | undefined,
  audioInputId: string | undefined,
  videoInputId: string | undefined,
  setViewAudioDeviceId: (audio: string | undefined) => void,
  setViewVideoDeviceId: (video: string | undefined) => void
): { mediaStream: MediaStream | undefined } => {
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(
    undefined
  );
  useEffect(() => {
    if (audioInputId === "none" || videoInputId === "none") {
      navigator.mediaDevices
        .getUserMedia({
          audio:
            audioInputId === "none"
              ? false
              : audioInputId
              ? { deviceId: audioInputId }
              : true,
          video:
            videoInputId === "none"
              ? false
              : videoInputId
              ? { deviceId: videoInputId }
              : true,
        })
        .then((stream) => {
          // TODO: videoもaudioもoffの場合は、無音のstreamを生成する
          if (audioInputId !== "none" && videoInputId !== "none") {
            setMediaStream(stream);
            stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
              const tmpDevice = videoInputs?.find(
                (device: DeviceType) => device.label === track.label
              );
              setViewVideoDeviceId(tmpDevice?.deviceId);
            });
            stream.getAudioTracks().forEach((track: MediaStreamTrack) => {
              const tmpDevice = audioInputs?.find(
                (device: DeviceType) => device.label === track.label
              );
              setViewAudioDeviceId(tmpDevice?.deviceId);
            });
          } else {
            const initStream = new MediaStream();
            if (audioInputId === "none") {
              console.warn("audio streamの取得");
              const audioCtx = new AudioContext();
              const destination = audioCtx.createMediaStreamDestination();
              const audioStream = destination.stream;
              initStream.addTrack(audioStream.getAudioTracks()[0]);
              setViewAudioDeviceId(undefined);
            } else {
              initStream.addTrack(stream.getAudioTracks()[0]);
              stream.getAudioTracks().forEach((track: MediaStreamTrack) => {
                const tmpDevice = audioInputs?.find(
                  (device: DeviceType) => device.label === track.label
                );
                setViewAudioDeviceId(tmpDevice?.deviceId);
              });
            }
            if (videoInputId === "none") {
              console.warn("canvas streamの取得");
              const canvas = document.createElement("canvas");
              const canvasStream = canvas.captureStream(30);
              initStream.addTrack(canvasStream.getVideoTracks()[0]);
              setViewVideoDeviceId(undefined);
            } else {
              initStream.addTrack(stream.getVideoTracks()[0]);
              stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
                const tmpDevice = videoInputs?.find(
                  (device: DeviceType) => device.label === track.label
                );
                setViewVideoDeviceId(tmpDevice?.deviceId);
              });
            }
            setMediaStream(initStream);
          }
        })
        .catch((err) => {
          console.error("enumerateDevice ERROR:", err);
        });
    } else {
      // const audioCtx = new AudioContext();
      // const destination = audioCtx.createMediaStreamDestination();
      // const mediaStream = destination.stream;
      // setMediaStream(mediaStream);
      navigator.mediaDevices
        .getUserMedia({
          audio: audioInputId === undefined ? true : { deviceId: audioInputId },
          video: videoInputId === undefined ? true : { deviceId: videoInputId },
        })
        .then((stream) => {
          setTimeout(() => {
            stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
              const tmpDevice = videoInputs?.find(
                (device: DeviceType) => device.label === track.label
              );
              setViewVideoDeviceId(tmpDevice?.deviceId);
            });
            stream.getAudioTracks().forEach((track: MediaStreamTrack) => {
              const tmpDevice = audioInputs?.find(
                (device: DeviceType) => device.label === track.label
              );
              setViewAudioDeviceId(tmpDevice?.deviceId);
            });
          }, 1000);
          // TODO: videoもaudioもoffの場合は、無音のstreamを生成する
          setMediaStream(stream);
        })
        .catch((err) => {
          console.error("enumerateDevice ERROR:", err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [audioInputId, videoInputId, audioInputs, videoInputs]);

  return { mediaStream };
};
