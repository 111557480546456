import { RequestUrl } from "./interface/RequestUrl";

export const requestUrls: RequestUrl[] = [
  {
    url: "https://prod.rura-api.tokyo/ping",
    type: "shop",
    status: "未確認",
    title: "RURA APIサーバ",
    detail: "",
    checkType: "fetch",
  },
  // {
  //   url: "https://rura-storage-product.s3.amazonaws.com/",
  //   type: "shop",
  //   status: "未確認",
  //   title: "RURA メディアストレージ",
  //   detail: "",
  //   checkType: "fetch",
  // },
  {
    url: "https://hooks.slack.com",
    type: "shop",
    status: "未確認",
    title: "slack hooks",
    detail: "",
    checkType: "open",
  },
  {
    url: "https://emma-prod.rura-emma.tokyo",
    type: "shop",
    status: "未確認",
    title: "RURA 接客状態管理(EMMA)",
    detail: "",
    checkType: "grpc",
  },
  {
    url: "https://anydesk.com/ja",
    type: "shop",
    status: "未確認",
    title: "遠隔サポートツール(anydesk)",
    detail: "",
    checkType: "open",
  },
  {
    url: "https://prod.rura-api.tokyo/ping",
    type: "worker",
    status: "未確認",
    title: "RURA APIサーバ",
    detail: "",
    checkType: "fetch",
  },
  // {
  //   url: "https://rura-storage-product.s3.amazonaws.com/",
  //   type: "worker",
  //   status: "未確認",
  //   title: "RURA メディアストレージ",
  //   detail: "",
  //   checkType: "fetch",
  // },
  {
    url: "https://management.rura.tokyo/signin",
    type: "worker",
    status: "未確認",
    title: "RURA 管理画面",
    detail: "",
    checkType: "open",
  },
  {
    url: "https://support.timeleap-rura.com/",
    type: "worker",
    status: "未確認",
    title: "RURA サポートサイト",
    detail: "",
    checkType: "open",
  },
  {
    url: "https://emma-prod.rura-emma.tokyo",
    type: "worker",
    status: "未確認",
    title: "RURA 接客状態管理(EMMA)",
    detail: "",
    checkType: "grpc",
  },
];
