import styled from "@emotion/styled";

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const SelectStyled: any = styled.select`
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  border-color: ${(props: any) => (props.hover ? "#999" : "#999")};
  background-color: ${(props: any) =>
    props.hover ? "rgba(0,0,0,0.08)" : "#ffffff"};
`;

export default SelectStyled;
