import { addZero } from "./TextUtil";
export default class DateTime {
  toString(datetime: Date) {
    let year = datetime.getFullYear();
    let month = datetime.getMonth() + 1;
    let day = datetime.getDate();
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();
    return `${year}/${month}/${day}-${hour}:${minute}:${second}`;
  }

  toTimeString(datetime: Date) {
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();
    return `${addZero(hour)}:${addZero(minute)}:${addZero(second)}`;
  }

  now() {
    const dateTime = new DateTime();
    return dateTime.toString(new Date());
  }
}
