import { requestGet, requestDomainCheck } from "src/api/domainCheckRequest";

export const useCheckS3 = (
  setStorageStatus: (storageStatus: -1 | 0 | 1) => void
): {
  startCheckStorage: () => void;
} => {
  const startCheckStorage = async () => {
    const res = await requestGet(`${process.env.REACT_APP_API_URL}/health/s3`);
    const imageUrl = res[1].data.url;
    const result = requestDomainCheck(imageUrl);
    result.then((res: any) => {
      if (res) {
        if (res[0] === 200) {
          setStorageStatus(1);
        } else {
          setStorageStatus(-1);
        }
      }
    });
  };
  return { startCheckStorage };
};
