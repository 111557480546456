import { useState } from "react";
import { useEvent } from "react-use";
import { DataConnection, MediaConnection } from "skyway-js";

export const usePeerEvent = (
  peer: any,
  mediaStream: MediaStream | undefined,
  setConnectStatus: (connectStatus: boolean) => void,
  setMediaConnection: (mediaConnection: MediaConnection | undefined) => void
): {
  anotherMediaStream: MediaStream | undefined;
  setAnotherMediaStream: (anotherMediaStream: MediaStream | undefined) => void;
} => {
  const [anotherMediaStream, setAnotherMediaStream] = useState<
    MediaStream | undefined
  >(undefined);

  useEvent(
    "open",
    async () => {
      if (peer === undefined) return;
    },
    peer
  );

  useEvent(
    "call",
    (mediaConnection: MediaConnection) => {
      if (peer === undefined || !mediaStream) return;
      setMediaConnection(mediaConnection);
      mediaConnection.answer(mediaStream);
    },
    peer
  );

  useEvent(
    "connection",
    (dataConnection: DataConnection) => {
      if (peer === undefined) return;
    },
    peer
  );

  useEvent(
    "error",
    (error: Error) => {
      console.error(error);
    },
    peer
  );

  useEvent(
    "close",
    () => {
      console.warn("通信が切断しました");
      setConnectStatus(false);
      setMediaConnection(undefined);
    },
    peer
  );

  return { anotherMediaStream, setAnotherMediaStream };
};
