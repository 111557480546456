export const requestDomainCheck = async (url: string) => {
  const headers = new Headers();

  // headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "GET",
      headers,
    });
    console.warn("res", res);
    return [
      await res.status,
      (await res.status) === 200 ? "" : await res.text(),
    ];
  } catch (e) {
    throw await e;
  }
};

export const requestSlack = async (url: string) => {
  console.warn("requestSlack", url);
  const headers = new Headers();
  headers.append("Content-type", "application/json");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        text: "text",
      }),
    });
    console.warn("res", res);
    return [
      await res.status,
      (await res.status) === 200 ? "" : await res.text(),
    ];
  } catch (e) {
    throw await e;
  }
};

export const isMe = async (token: string) => {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/v1/user/me/`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authentication-Token", token);
  try {
    const res = await fetch(url, {
      method: "GET",
      headers,
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};

export const requestGet = async (url: string) => {
  const headers = new Headers();

  // headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "GET",
      headers,
    });
    console.warn("res", res);
    return [
      await res.status,
      (await res.status) === 200 ? await res.json() : "",
    ];
  } catch (e) {
    throw await e;
  }
};
