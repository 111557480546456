import { VFC } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";

type Props = {
  active?: boolean;
};

export const BrowserAlert: VFC<Props> = ({ active }: Props) => {
  return (
    <Wrapper style={{ display: active ? "flex" : "none" }}>
      <Content>
        <WarningOutlined />
        <span>Google Chromeからアクセスしてください。</span>
        本ツールは、お使いのブラウザではご利用いただけません。
        <br />
        Google Chromeから再度アクセスしてください。
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  z-index: 999999;
`;
const Content = styled.div`
  color: #fff;
  text-align: center;
  span {
    font-size: 24px;
    margin: 0 0 24px 0;
    display: block;
  }
  svg {
    width: 48px;
    height: 48px;
  }
`;
