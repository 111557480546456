import { MediaConnection } from "skyway-js";
import { useRTCInsight } from "src/hooks/useRTCInsight";
import { LogFuncType } from "src/types/networkLog";

export const useNetworkLog = (
  mediaConnection: MediaConnection | undefined
): {
  getStatus: LogFuncType;
} => {
  const { getNetworkStats } = useRTCInsight({
    mediaConnection,
  });

  const getStatus = async () => {
    // ログに合うように整形する
    return await getNetworkStats();
  };

  return { getStatus };
};
