import { RequestUrl } from "src/interface/RequestUrl";
import DateTime from "src/utilities/DateTime";
import { NetworkLogType } from "src/types/networkLog";

export const useCsv = (): {
  handleDLcsv: (domains: RequestUrl[], isChrome: boolean) => void;
  handleLogDLcsv: (domains: NetworkLogType[]) => void;
} => {
  const handleLogDLcsv = async (logList: NetworkLogType[]) => {
    const array_data: any = [
      ["時間", "受信byte(音声)", "受信jitter(音声)", "受信byte(映像)",
        // "送信byte(音声)",
        "送信jitter(音声)",
        // "送信byte(映像)"
      ],
    ];
    logList.map((item: NetworkLogType) => {
      let item_data = [
        item.time,
        item.receiveVoiceByte,
        item.receiveJitterBufferDelay,
        item.receiveVideoByte,
        // item.sendVoiceByte,
        item.sendJitterBufferDelay,
        // item.sendVideoByte,
      ];
      array_data.push(item_data);
      return array_data;
    });
    let csv_data = array_data
      .map(function (l: any) {
        return l.join(",");
      })
      .join("\r\n");
    const dateTime = new DateTime();
    let timestamp = dateTime.toString(new Date());
    const csvName = `p2p_network_domain_check_log_${timestamp}.csv`;
    downloadCsv(csv_data, csvName);
  };

  const handleDLcsv = async (domains: RequestUrl[], isChrome: boolean) => {
    const array_data: any = [
      ["URL", "PCタイプ", "ステータス", "名前", "詳細", "確認方法"],
    ];
    domains.map((item: RequestUrl) => {
      let item_data = [
        item.url,
        item.type,
        item.status,
        item.title,
        item.detail,
        item.checkType,
      ];
      array_data.push(item_data);
      return array_data;
    });
    let csv_data = `chrome browser:${isChrome}\r\n`
    csv_data += array_data
      .map(function (l: any) {
        return l.join(",");
      })
      .join("\r\n");
    const dateTime = new DateTime();
    let timestamp = dateTime.toString(new Date());
    const csvName = `p2p_network_domain_check_${timestamp}.csv`;
    downloadCsv(csv_data, csvName);
  };

  const downloadCsv = (csv_data: any, csvName: string) => {
    //文字コード
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    //BLOB生成してダウンロード実行
    const blob = new Blob([bom, csv_data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    a.download = csvName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
  };

  return { handleDLcsv, handleLogDLcsv };
};
