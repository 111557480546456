import { standardizeReport } from "rtcstats-wrapper";
import { useCallback } from "react";
import { MediaConnection } from "skyway-js";

export const useCheckTurnUsed = (
  mediaConnection: MediaConnection | undefined
) => {

  const checkTurnUsed = useCallback(async () => {
    const connection: any = mediaConnection?.getPeerConnection();
    const stats = await connection.getStats();
    let isTurn: boolean = false
    stats.forEach((report: any) => {
      // if (report.type === "candidate-pair") { console.log("report", report); }
      if (report.type === "candidate-pair") {
        const localCandidateId = report.localCandidateId;
        const remoteCandidateId = report.remoteCandidateId;
        const localCandidate = stats.get(localCandidateId);
        const remoteCandidate = stats.get(remoteCandidateId);
        // console.log("Local Candidate:", localCandidate);
        // console.log("Remote Candidate:", remoteCandidate);
        if (
          localCandidate.candidateType === "relay" ||
          remoteCandidate.candidateType === "relay"
        ) {
          console.log("TURN is being used.");
          isTurn = true; 
        }
      }
    });
    return isTurn;
  }, [mediaConnection]);


  // const checkTurnUsed = useCallback(async () => {
  //   const connection: any = mediaConnection?.getPeerConnection();
  //   let report: RTCStatsReport;
  //   try {
  //     report = standardizeReport(await connection.getStats());
  //   } catch (e) {
  //     // standardizeReport では、サポートしていない属性がブラウザへ追加された場合に
  //     // > Received an unknown stats-type string: media-playout
  //     // のような Error を throw する。
  //     // see: https://github.com/skyway-lab/rtcstats-wrapper/blob/fa1fbc0aac9c5dedc561cabc0c01a3abe5abcd36/src/standardizers/base.js#L172
  //     // datadog へ大量のエラーログが送信されてしまうため、throw された場合は何もせず処理を終了させる。
  //     return;
  //   }
  //   console.log("report", report);
  //   const rtcIceCandidatePairsLocalCandidateIds = report
  //     ?.get("RTCIceCandidatePairs")
  //     ?.filter((value: any) => value.nominated && value.state === "succeeded")
  //     .map((item: any) => item.localCandidateId);
  //   const rtcLocalIceCandidatesIds = report
  //     ?.get("RTCLocalIceCandidates")
  //     ?.filter((value: any) => value.candidateType === "relay")
  //     .map((item: any) => item.id);
  //   const result = rtcIceCandidatePairsLocalCandidateIds?.some((item: string) =>
  //     rtcLocalIceCandidatesIds.includes(item)
  //   );
  //   return result;
  // }, [mediaConnection]);

  return {
    checkTurnUsed,
  };
};
