import { VFC, RefObject, useState } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { SoundOutlined, NotificationOutlined } from "@ant-design/icons";
import { IconHover } from "src/layout/Components/IconHover";
export const VideoView: VFC<{
  videoRef: RefObject<HTMLVideoElement> | null;
  isLocal: boolean;
  view: boolean;
}> = ({
  videoRef,
  isLocal,
  view,
}: {
  videoRef: RefObject<HTMLVideoElement> | null;
  isLocal: boolean;
  view: boolean;
}) => {
  const [muted, setMuted] = useState<boolean>(false);

  const changeMuted = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = !muted;
      setMuted(!muted);
    }
  };

  return (
    <Card>
      <CardContent
        sx={{
          width: "100%",
          position: "relative",
          padding: "0px",
        }}
      >
        <video
          ref={videoRef}
          muted={isLocal}
          autoPlay
          style={{
            width: "100%",
            aspectRatio: "1.777",
            objectFit: "cover",
          }}
        ></video>
        {!isLocal && !view && (
          <Box
            style={{
              backgroundColor: "#D9D9D9",
              width: "100%",
              aspectRatio: "1.777",
              position: "absolute",
              left: "0",
              top: "0",
            }}
          >
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "14px",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              未接続
            </Typography>
          </Box>
        )}
        <Box
          style={{
            padding: "8px 16px",
            height: "32px",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {isLocal ? "あなた" : "相手"}のカメラ映像
          </Typography>
          {!isLocal &&
            (muted ? (
              <IconHover
                style={{
                  width: "20px",
                  marginLeft: "10px",
                }}
              >
                <NotificationOutlined onClick={changeMuted} />
              </IconHover>
            ) : (
              <IconHover
                style={{
                  width: "20px",
                  padding: "4px",
                  marginLeft: "10px",
                }}
              >
                <SoundOutlined onClick={changeMuted} />
              </IconHover>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};
