/**
 * @fileoverview gRPC-Web generated client stub for messaging.service.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.19.4
// source: messaging/service/v1/message.proto

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from "grpc-web";

import * as messaging_service_v1_message_pb from "../../../messaging/service/v1/message_pb";

export class MessageServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any }
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, "");
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPublish = new grpcWeb.MethodDescriptor(
    "/messaging.service.v1.MessageService/Publish",
    grpcWeb.MethodType.UNARY,
    messaging_service_v1_message_pb.PublishRequest,
    messaging_service_v1_message_pb.PublishResponse,
    (request: messaging_service_v1_message_pb.PublishRequest) => {
      return request.serializeBinary();
    },
    messaging_service_v1_message_pb.PublishResponse.deserializeBinary
  );

  publish(
    request: messaging_service_v1_message_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null
  ): Promise<messaging_service_v1_message_pb.PublishResponse>;

  publish(
    request: messaging_service_v1_message_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (
      err: grpcWeb.RpcError,
      response: messaging_service_v1_message_pb.PublishResponse
    ) => void
  ): grpcWeb.ClientReadableStream<messaging_service_v1_message_pb.PublishResponse>;

  publish(
    request: messaging_service_v1_message_pb.PublishRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (
      err: grpcWeb.RpcError,
      response: messaging_service_v1_message_pb.PublishResponse
    ) => void
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + "/messaging.service.v1.MessageService/Publish",
        request,
        metadata || {},
        this.methodDescriptorPublish,
        callback
      );
    }
    return this.client_.unaryCall(
      this.hostname_ + "/messaging.service.v1.MessageService/Publish",
      request,
      metadata || {},
      this.methodDescriptorPublish
    );
  }

  methodDescriptorSubscribe = new grpcWeb.MethodDescriptor(
    "/messaging.service.v1.MessageService/Subscribe",
    grpcWeb.MethodType.SERVER_STREAMING,
    messaging_service_v1_message_pb.SubscribeRequest,
    messaging_service_v1_message_pb.SubscribeResponse,
    (request: messaging_service_v1_message_pb.SubscribeRequest) => {
      return request.serializeBinary();
    },
    messaging_service_v1_message_pb.SubscribeResponse.deserializeBinary
  );

  subscribe(
    request: messaging_service_v1_message_pb.SubscribeRequest,
    metadata?: grpcWeb.Metadata
  ): grpcWeb.ClientReadableStream<messaging_service_v1_message_pb.SubscribeResponse> {
    return this.client_.serverStreaming(
      this.hostname_ + "/messaging.service.v1.MessageService/Subscribe",
      request,
      metadata || {},
      this.methodDescriptorSubscribe
    );
  }
}
