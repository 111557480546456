import { combineReducers } from "@reduxjs/toolkit";
import menu from "src/store/reducers/menu";

const rootReducer = combineReducers({
  menu: menu,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
