// source: messaging/model/v1/event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var messaging_model_v1_event_data_pb = require('../../../messaging/model/v1/event_data_pb.js');
goog.object.extend(proto, messaging_model_v1_event_data_pb);
goog.exportSymbol('proto.messaging.model.v1.Event', null, global);
goog.exportSymbol('proto.messaging.model.v1.Event.DataCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.messaging.model.v1.Event.oneofGroups_);
};
goog.inherits(proto.messaging.model.v1.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.Event.displayName = 'proto.messaging.model.v1.Event';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.messaging.model.v1.Event.oneofGroups_ = [[2,3,4,5,6,7,8,9,10,12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.messaging.model.v1.Event.DataCase = {
  DATA_NOT_SET: 0,
  HEART_BEAT: 2,
  ACCEPT_RECEPTION_START: 3,
  SHOP_LOGIN_SUCCESS: 4,
  UPDATE_STAFFS: 5,
  UPDATE_SHOPS: 6,
  GO_BACK_WAIT_ROOM: 7,
  CONFLICT_STAFF: 8,
  CLEAR_ANDROID_BUTTON_NOTICE: 9,
  DUPLICATE_STAFF_LOGIN: 10,
  START_SUBSCRIBE: 12,
  REBOOT_PC: 13,
  REBOOT_ANDROID: 14,
  SHOP_DETECTION: 15,
  CHECK_TURN_USED: 16
};

/**
 * @return {proto.messaging.model.v1.Event.DataCase}
 */
proto.messaging.model.v1.Event.prototype.getDataCase = function() {
  return /** @type {proto.messaging.model.v1.Event.DataCase} */(jspb.Message.computeOneofCase(this, proto.messaging.model.v1.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    heartBeat: (f = msg.getHeartBeat()) && messaging_model_v1_event_data_pb.HeartBeat.toObject(includeInstance, f),
    acceptReceptionStart: (f = msg.getAcceptReceptionStart()) && messaging_model_v1_event_data_pb.AcceptReceptionStart.toObject(includeInstance, f),
    shopLoginSuccess: (f = msg.getShopLoginSuccess()) && messaging_model_v1_event_data_pb.ShopLoginSuccess.toObject(includeInstance, f),
    updateStaffs: (f = msg.getUpdateStaffs()) && messaging_model_v1_event_data_pb.UpdateStaffs.toObject(includeInstance, f),
    updateShops: (f = msg.getUpdateShops()) && messaging_model_v1_event_data_pb.UpdateShops.toObject(includeInstance, f),
    goBackWaitRoom: (f = msg.getGoBackWaitRoom()) && messaging_model_v1_event_data_pb.GoBackWaitRoom.toObject(includeInstance, f),
    conflictStaff: (f = msg.getConflictStaff()) && messaging_model_v1_event_data_pb.ConflictStaff.toObject(includeInstance, f),
    clearAndroidButtonNotice: (f = msg.getClearAndroidButtonNotice()) && messaging_model_v1_event_data_pb.ClearAndroidButtonNotice.toObject(includeInstance, f),
    duplicateStaffLogin: (f = msg.getDuplicateStaffLogin()) && messaging_model_v1_event_data_pb.DuplicateStaffLogin.toObject(includeInstance, f),
    startSubscribe: (f = msg.getStartSubscribe()) && messaging_model_v1_event_data_pb.StartSubscribe.toObject(includeInstance, f),
    rebootPc: (f = msg.getRebootPc()) && messaging_model_v1_event_data_pb.RebootPc.toObject(includeInstance, f),
    rebootAndroid: (f = msg.getRebootAndroid()) && messaging_model_v1_event_data_pb.RebootAndroid.toObject(includeInstance, f),
    shopDetection: (f = msg.getShopDetection()) && messaging_model_v1_event_data_pb.ShopDetection.toObject(includeInstance, f),
    checkTurnUsed: (f = msg.getCheckTurnUsed()) && messaging_model_v1_event_data_pb.CheckTurnUsed.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.Event}
 */
proto.messaging.model.v1.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.Event;
  return proto.messaging.model.v1.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.Event}
 */
proto.messaging.model.v1.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = new messaging_model_v1_event_data_pb.HeartBeat;
      reader.readMessage(value,messaging_model_v1_event_data_pb.HeartBeat.deserializeBinaryFromReader);
      msg.setHeartBeat(value);
      break;
    case 3:
      var value = new messaging_model_v1_event_data_pb.AcceptReceptionStart;
      reader.readMessage(value,messaging_model_v1_event_data_pb.AcceptReceptionStart.deserializeBinaryFromReader);
      msg.setAcceptReceptionStart(value);
      break;
    case 4:
      var value = new messaging_model_v1_event_data_pb.ShopLoginSuccess;
      reader.readMessage(value,messaging_model_v1_event_data_pb.ShopLoginSuccess.deserializeBinaryFromReader);
      msg.setShopLoginSuccess(value);
      break;
    case 5:
      var value = new messaging_model_v1_event_data_pb.UpdateStaffs;
      reader.readMessage(value,messaging_model_v1_event_data_pb.UpdateStaffs.deserializeBinaryFromReader);
      msg.setUpdateStaffs(value);
      break;
    case 6:
      var value = new messaging_model_v1_event_data_pb.UpdateShops;
      reader.readMessage(value,messaging_model_v1_event_data_pb.UpdateShops.deserializeBinaryFromReader);
      msg.setUpdateShops(value);
      break;
    case 7:
      var value = new messaging_model_v1_event_data_pb.GoBackWaitRoom;
      reader.readMessage(value,messaging_model_v1_event_data_pb.GoBackWaitRoom.deserializeBinaryFromReader);
      msg.setGoBackWaitRoom(value);
      break;
    case 8:
      var value = new messaging_model_v1_event_data_pb.ConflictStaff;
      reader.readMessage(value,messaging_model_v1_event_data_pb.ConflictStaff.deserializeBinaryFromReader);
      msg.setConflictStaff(value);
      break;
    case 9:
      var value = new messaging_model_v1_event_data_pb.ClearAndroidButtonNotice;
      reader.readMessage(value,messaging_model_v1_event_data_pb.ClearAndroidButtonNotice.deserializeBinaryFromReader);
      msg.setClearAndroidButtonNotice(value);
      break;
    case 10:
      var value = new messaging_model_v1_event_data_pb.DuplicateStaffLogin;
      reader.readMessage(value,messaging_model_v1_event_data_pb.DuplicateStaffLogin.deserializeBinaryFromReader);
      msg.setDuplicateStaffLogin(value);
      break;
    case 12:
      var value = new messaging_model_v1_event_data_pb.StartSubscribe;
      reader.readMessage(value,messaging_model_v1_event_data_pb.StartSubscribe.deserializeBinaryFromReader);
      msg.setStartSubscribe(value);
      break;
    case 13:
      var value = new messaging_model_v1_event_data_pb.RebootPc;
      reader.readMessage(value,messaging_model_v1_event_data_pb.RebootPc.deserializeBinaryFromReader);
      msg.setRebootPc(value);
      break;
    case 14:
      var value = new messaging_model_v1_event_data_pb.RebootAndroid;
      reader.readMessage(value,messaging_model_v1_event_data_pb.RebootAndroid.deserializeBinaryFromReader);
      msg.setRebootAndroid(value);
      break;
    case 15:
      var value = new messaging_model_v1_event_data_pb.ShopDetection;
      reader.readMessage(value,messaging_model_v1_event_data_pb.ShopDetection.deserializeBinaryFromReader);
      msg.setShopDetection(value);
      break;
    case 16:
      var value = new messaging_model_v1_event_data_pb.CheckTurnUsed;
      reader.readMessage(value,messaging_model_v1_event_data_pb.CheckTurnUsed.deserializeBinaryFromReader);
      msg.setCheckTurnUsed(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeartBeat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      messaging_model_v1_event_data_pb.HeartBeat.serializeBinaryToWriter
    );
  }
  f = message.getAcceptReceptionStart();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      messaging_model_v1_event_data_pb.AcceptReceptionStart.serializeBinaryToWriter
    );
  }
  f = message.getShopLoginSuccess();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      messaging_model_v1_event_data_pb.ShopLoginSuccess.serializeBinaryToWriter
    );
  }
  f = message.getUpdateStaffs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      messaging_model_v1_event_data_pb.UpdateStaffs.serializeBinaryToWriter
    );
  }
  f = message.getUpdateShops();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      messaging_model_v1_event_data_pb.UpdateShops.serializeBinaryToWriter
    );
  }
  f = message.getGoBackWaitRoom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      messaging_model_v1_event_data_pb.GoBackWaitRoom.serializeBinaryToWriter
    );
  }
  f = message.getConflictStaff();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      messaging_model_v1_event_data_pb.ConflictStaff.serializeBinaryToWriter
    );
  }
  f = message.getClearAndroidButtonNotice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      messaging_model_v1_event_data_pb.ClearAndroidButtonNotice.serializeBinaryToWriter
    );
  }
  f = message.getDuplicateStaffLogin();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      messaging_model_v1_event_data_pb.DuplicateStaffLogin.serializeBinaryToWriter
    );
  }
  f = message.getStartSubscribe();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      messaging_model_v1_event_data_pb.StartSubscribe.serializeBinaryToWriter
    );
  }
  f = message.getRebootPc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      messaging_model_v1_event_data_pb.RebootPc.serializeBinaryToWriter
    );
  }
  f = message.getRebootAndroid();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      messaging_model_v1_event_data_pb.RebootAndroid.serializeBinaryToWriter
    );
  }
  f = message.getShopDetection();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      messaging_model_v1_event_data_pb.ShopDetection.serializeBinaryToWriter
    );
  }
  f = message.getCheckTurnUsed();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      messaging_model_v1_event_data_pb.CheckTurnUsed.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.Event.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional HeartBeat heart_beat = 2;
 * @return {?proto.messaging.model.v1.HeartBeat}
 */
proto.messaging.model.v1.Event.prototype.getHeartBeat = function() {
  return /** @type{?proto.messaging.model.v1.HeartBeat} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.HeartBeat, 2));
};


/**
 * @param {?proto.messaging.model.v1.HeartBeat|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setHeartBeat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearHeartBeat = function() {
  return this.setHeartBeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasHeartBeat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AcceptReceptionStart accept_reception_start = 3;
 * @return {?proto.messaging.model.v1.AcceptReceptionStart}
 */
proto.messaging.model.v1.Event.prototype.getAcceptReceptionStart = function() {
  return /** @type{?proto.messaging.model.v1.AcceptReceptionStart} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.AcceptReceptionStart, 3));
};


/**
 * @param {?proto.messaging.model.v1.AcceptReceptionStart|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setAcceptReceptionStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearAcceptReceptionStart = function() {
  return this.setAcceptReceptionStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasAcceptReceptionStart = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ShopLoginSuccess shop_login_success = 4;
 * @return {?proto.messaging.model.v1.ShopLoginSuccess}
 */
proto.messaging.model.v1.Event.prototype.getShopLoginSuccess = function() {
  return /** @type{?proto.messaging.model.v1.ShopLoginSuccess} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.ShopLoginSuccess, 4));
};


/**
 * @param {?proto.messaging.model.v1.ShopLoginSuccess|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setShopLoginSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearShopLoginSuccess = function() {
  return this.setShopLoginSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasShopLoginSuccess = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateStaffs update_staffs = 5;
 * @return {?proto.messaging.model.v1.UpdateStaffs}
 */
proto.messaging.model.v1.Event.prototype.getUpdateStaffs = function() {
  return /** @type{?proto.messaging.model.v1.UpdateStaffs} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.UpdateStaffs, 5));
};


/**
 * @param {?proto.messaging.model.v1.UpdateStaffs|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setUpdateStaffs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearUpdateStaffs = function() {
  return this.setUpdateStaffs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasUpdateStaffs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UpdateShops update_shops = 6;
 * @return {?proto.messaging.model.v1.UpdateShops}
 */
proto.messaging.model.v1.Event.prototype.getUpdateShops = function() {
  return /** @type{?proto.messaging.model.v1.UpdateShops} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.UpdateShops, 6));
};


/**
 * @param {?proto.messaging.model.v1.UpdateShops|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setUpdateShops = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearUpdateShops = function() {
  return this.setUpdateShops(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasUpdateShops = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GoBackWaitRoom go_back_wait_room = 7;
 * @return {?proto.messaging.model.v1.GoBackWaitRoom}
 */
proto.messaging.model.v1.Event.prototype.getGoBackWaitRoom = function() {
  return /** @type{?proto.messaging.model.v1.GoBackWaitRoom} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.GoBackWaitRoom, 7));
};


/**
 * @param {?proto.messaging.model.v1.GoBackWaitRoom|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setGoBackWaitRoom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearGoBackWaitRoom = function() {
  return this.setGoBackWaitRoom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasGoBackWaitRoom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ConflictStaff conflict_staff = 8;
 * @return {?proto.messaging.model.v1.ConflictStaff}
 */
proto.messaging.model.v1.Event.prototype.getConflictStaff = function() {
  return /** @type{?proto.messaging.model.v1.ConflictStaff} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.ConflictStaff, 8));
};


/**
 * @param {?proto.messaging.model.v1.ConflictStaff|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setConflictStaff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearConflictStaff = function() {
  return this.setConflictStaff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasConflictStaff = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ClearAndroidButtonNotice clear_android_button_notice = 9;
 * @return {?proto.messaging.model.v1.ClearAndroidButtonNotice}
 */
proto.messaging.model.v1.Event.prototype.getClearAndroidButtonNotice = function() {
  return /** @type{?proto.messaging.model.v1.ClearAndroidButtonNotice} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.ClearAndroidButtonNotice, 9));
};


/**
 * @param {?proto.messaging.model.v1.ClearAndroidButtonNotice|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setClearAndroidButtonNotice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearClearAndroidButtonNotice = function() {
  return this.setClearAndroidButtonNotice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasClearAndroidButtonNotice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DuplicateStaffLogin duplicate_staff_login = 10;
 * @return {?proto.messaging.model.v1.DuplicateStaffLogin}
 */
proto.messaging.model.v1.Event.prototype.getDuplicateStaffLogin = function() {
  return /** @type{?proto.messaging.model.v1.DuplicateStaffLogin} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.DuplicateStaffLogin, 10));
};


/**
 * @param {?proto.messaging.model.v1.DuplicateStaffLogin|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setDuplicateStaffLogin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearDuplicateStaffLogin = function() {
  return this.setDuplicateStaffLogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasDuplicateStaffLogin = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional StartSubscribe start_subscribe = 12;
 * @return {?proto.messaging.model.v1.StartSubscribe}
 */
proto.messaging.model.v1.Event.prototype.getStartSubscribe = function() {
  return /** @type{?proto.messaging.model.v1.StartSubscribe} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.StartSubscribe, 12));
};


/**
 * @param {?proto.messaging.model.v1.StartSubscribe|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setStartSubscribe = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearStartSubscribe = function() {
  return this.setStartSubscribe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasStartSubscribe = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional RebootPc reboot_pc = 13;
 * @return {?proto.messaging.model.v1.RebootPc}
 */
proto.messaging.model.v1.Event.prototype.getRebootPc = function() {
  return /** @type{?proto.messaging.model.v1.RebootPc} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.RebootPc, 13));
};


/**
 * @param {?proto.messaging.model.v1.RebootPc|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setRebootPc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearRebootPc = function() {
  return this.setRebootPc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasRebootPc = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional RebootAndroid reboot_android = 14;
 * @return {?proto.messaging.model.v1.RebootAndroid}
 */
proto.messaging.model.v1.Event.prototype.getRebootAndroid = function() {
  return /** @type{?proto.messaging.model.v1.RebootAndroid} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.RebootAndroid, 14));
};


/**
 * @param {?proto.messaging.model.v1.RebootAndroid|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setRebootAndroid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearRebootAndroid = function() {
  return this.setRebootAndroid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasRebootAndroid = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ShopDetection shop_detection = 15;
 * @return {?proto.messaging.model.v1.ShopDetection}
 */
proto.messaging.model.v1.Event.prototype.getShopDetection = function() {
  return /** @type{?proto.messaging.model.v1.ShopDetection} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.ShopDetection, 15));
};


/**
 * @param {?proto.messaging.model.v1.ShopDetection|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setShopDetection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearShopDetection = function() {
  return this.setShopDetection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasShopDetection = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CheckTurnUsed check_turn_used = 16;
 * @return {?proto.messaging.model.v1.CheckTurnUsed}
 */
proto.messaging.model.v1.Event.prototype.getCheckTurnUsed = function() {
  return /** @type{?proto.messaging.model.v1.CheckTurnUsed} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_data_pb.CheckTurnUsed, 16));
};


/**
 * @param {?proto.messaging.model.v1.CheckTurnUsed|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setCheckTurnUsed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.messaging.model.v1.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearCheckTurnUsed = function() {
  return this.setCheckTurnUsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasCheckTurnUsed = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messaging.model.v1.Event.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messaging.model.v1.Event} returns this
*/
proto.messaging.model.v1.Event.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.Event} returns this
 */
proto.messaging.model.v1.Event.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.Event.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.messaging.model.v1);
