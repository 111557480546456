import { useState, useEffect } from "react";
import { RequestUrl } from "src/interface/RequestUrl";

export const usePopup = (
  urls: RequestUrl[]
): {
  popupUrls: RequestUrl[];
  openPopup: (url: string) => void;
  updateCheck: (index: number, flg: boolean) => void;
} => {
  const [domainUrls, setDomainUrls] = useState<RequestUrl[]>(
    urls.filter(
      (requestUrlData: RequestUrl) => requestUrlData.checkType === "open"
    )
  );

  useEffect(() => {
    console.warn("domainUrls", domainUrls);
  }, [domainUrls]);

  const openPopup = (url: string) => {
    const windowObjectReference = window.open(url, "mozillaWindow", "popup");
    if (windowObjectReference) {
      windowObjectReference.focus();
      setTimeout(() => {
        windowObjectReference.close();
      }, 5000);
    }
  };

  const updateCheck = (index: number, flg: boolean) => {
    const tmpDomains = domainUrls;
    tmpDomains[index].status = flg ? "接続完了" : "接続失敗";
    setDomainUrls(tmpDomains);
  };

  return { popupUrls: domainUrls, openPopup, updateCheck };
};
