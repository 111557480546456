import { VFC, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Tooltip,
  CardContent,
  OutlinedInput,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import { QuestionCircleOutlined, SnippetsOutlined } from "@ant-design/icons";
import { MainModal } from "src/layout/Main/Modal/const";
import { useCopyToClipboard } from "react-use";
import { alertNotification } from "src/utilities/Notification";
import { IconHover } from "src/layout/Components/IconHover";

export const ConnectArea: VFC<{
  setOpen: (flg: MainModal.ModalConnectStatus) => void;
  peerId: string;
  connectPeer: (peerId: string) => boolean;
}> = ({
  setOpen,
  peerId,
  connectPeer,
}: {
  setOpen: (flg: MainModal.ModalConnectStatus) => void;
  peerId: any;
  connectPeer: (peerId: string) => boolean;
}) => {
  const [anotherPeerId, setAnotherPeerId] = useState<string>("");
  const copyToClipboard = useCopyToClipboard()[1];
  // const uri = new URL(window.location.href);
  return (
    <Box sx={{ mt: "32px" }}>
      <Typography style={{ fontSize: "18px", margin: "0 0 16px" }}>
        あなたから接続する場合
      </Typography>
      <Card
        sx={{
          width: "100%",
          mt: "6px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <CardHeader
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          title={
            <Typography>
              {"IDで接続する"}
              <Tooltip
                title="接続相手のIDで接続する方法です。IDは事前に共有してもらってください。"
                placement="top"
              >
                <QuestionCircleOutlined style={{ marginLeft: "10px" }} />
              </Tooltip>
            </Typography>
          }
        />
        <CardContent>
          <Typography sx={{ fontSize: "14px", mb: "10px" }}>
            {"IDを入力して「接続する」をクリックしてください"}
          </Typography>
          <OutlinedInput
            type="text"
            sx={{ height: "36px" }}
            placeholder="接続先IDを入力"
            defaultValue={anotherPeerId}
            onChange={(e) => setAnotherPeerId(e.target.value)}
          />
          <Button
            color="primary"
            variant="contained"
            sx={{ width: "90px", ml: "10px", backgroundColor: "#00A0E9" }}
            onClick={() => {
              connectPeer(anotherPeerId);
            }}
          >
            接続する
          </Button>
        </CardContent>
      </Card>
      <Box sx={{ mt: "32px" }}>
        <Typography style={{ fontSize: "18px", margin: "0 0 16px" }}>
          相手から接続する場合
        </Typography>
        <Grid container justifyContent="space-between" sx={{ mt: "6px" }}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              title={
                <Typography>
                  {"IDで接続してもらう"}
                  <Tooltip
                    title="あなたのIDを接続相手に共有して接続する方法です。"
                    placement="top"
                  >
                    <QuestionCircleOutlined style={{ marginLeft: "10px" }} />
                  </Tooltip>
                </Typography>
              }
            />
            <CardContent>
              <Typography sx={{ fontSize: "14px", mt: "5px" }}>
                {"相手側から下記IDで接続してください。"}
              </Typography>
              <Box display="flex">
                <Typography sx={{ fontSize: "14px", mt: "19px" }}>
                  <InputLabel sx={{ color: "#000000", fontSize: "14px" }}>
                    あなたのID: {peerId}
                  </InputLabel>
                </Typography>
                <IconHover
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                    marginTop: "14px"
                  }}
                >
                  <SnippetsOutlined
                    onClick={() => {
                      copyToClipboard(peerId);
                      alertNotification("コピーしました。");
                    }}
                  />
                </IconHover>
              </Box>
            </CardContent>
          </Card>
          {/* <Card sx={{ width: "49%" }}>
            <CardHeader
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              title={
                <Typography sx={{ fontSize: "14px" }}>
                  {"URLで接続してもらう"}
                  <Tooltip title="後で決めます" placement="top">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography>
              }
            />
            <CardContent>
              <Typography sx={{ fontSize: "14px", mt: "5px" }}>
                {"下記URLに相手側からアクセスしてください。"}
              </Typography>
              <Typography sx={{ fontSize: "14px", mt: "19px" }}>
                <InputLabel sx={{ color: "#000000", fontSize: "14px" }}>
                  {`${uri.origin}?peer=${peerId}`}
                  <SnippetsOutlined
                    onClick={() => {
                      copyToClipboard(`${uri.origin}?peer=${peerId}`);
                      alertNotification("コピーしました。")
                    }}
                    style={{ marginLeft: "10px" }}
                  />
                </InputLabel>
              </Typography>
            </CardContent>
          </Card> */}
        </Grid>
      </Box>
    </Box>
  );
};
