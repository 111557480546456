import { Metadata, RpcError, Status } from "grpc-web";
import { finishedSubscribe, MessagingClient } from "src/grpc/client";
import { useBackoff } from "src/hooks/useBackoff";

import { useEffect, useState } from "react";
import {
  SubscribeRequest,
  SubscribeResponse,
} from "src/grpc/messaging/service/v1/message_pb";

export const useSubscribe = (
  setEmmaStatus: (emmaStatus: -1 | 0 | 1) => void
): { startSubScribe: () => void } => {
  const [subscribed, updateSubscribed] = useState<boolean>(false);
  // const [joined, updateJoined] = useState<boolean>(false);
  const [stream, updateStream] = useState<any>(undefined);
  // const [retrySubscribe, updateRetrySubscribe] = useState<number>(0);
  const { wait, reset } = useBackoff({
    initialDelayMs: 1000,
    maxDelayMs: 5000,
  });
  const roomId = "test";
  const userId = "test";

  // peer が破棄された場合の subscribe 停止処理
  useEffect(() => {
    if (stream === undefined || !subscribed) return;

    // peer が open -> close に遷移した場合に subscribe を停止。
    // ルームに再入室する必要があるため、入室フラグも off にする。
    stream.cancel();
    updateStream(undefined);
    updateSubscribed(false);
    // updateJoined(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribed, stream]);

  // エラー時ハンドラ
  const onError = async (err: RpcError) => {
    console.log("onError", err);

    // subscribe が意図せず終了した場合、
    // subscribedAtom の値を更新することで 当 useEffect の処理を再実行させ、新たに subscribe を開始させる。
    if (finishedSubscribe(err)) {
      await wait();
      updateSubscribed(false);
      // updateJoined(false);
      updateStream(undefined);
    }
  };

  const startSubScribe = () => {
    if (roomId === null || userId === undefined) return;
    if (stream !== undefined) return;

    const subscribeRequest = new SubscribeRequest();
    subscribeRequest.setGroupId(roomId);
    subscribeRequest.setStaffId(userId);
    subscribeRequest.setPeerId("test_peer_id");
    const newStream = MessagingClient.subscribe(subscribeRequest, {});
    newStream.on("error", onError);
    updateStream(newStream);
  };
  // subscribe 開始処理
  // useEffect(() => {
  //   if (roomId === null || userId === undefined) return;
  //   if (stream !== undefined) return;

  //   const subscribeRequest = new SubscribeRequest();
  //   subscribeRequest.setGroupId(roomId);
  //   subscribeRequest.setStaffId(userId);
  //   subscribeRequest.setPeerId("test_peer_id");
  //   const newStream = MessagingClient.subscribe(subscribeRequest, {});
  //   newStream.on("error", onError);
  //   updateStream(newStream);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   roomId,
  //   userId,
  //   stream,
  //   // retry, // retry を deps に含めることで、wait の呼び出し毎に subscribe を開始させる（再subscribeの用途）
  // ]);

  useEffect(() => {
    if (subscribed) reset();
  }, [subscribed, reset]);

  // subscribe のリトライ回数を保持
  // useEffect(() => updateRetrySubscribe(retry), [retry, updateRetrySubscribe]);

  const onData = (data: SubscribeResponse) =>
    console.log("onData", data.toObject());
  const onMetadata = (meta: Metadata) => console.log("onMetadata", meta);
  const onStatus = (status: Status) => {
    if (status.code === 16) {
      setEmmaStatus(1);
    } else {
      setEmmaStatus(-1);
    }
    console.log("onStatus", status);
  };
  const onEnd = () => console.log("onEnd");

  // ハンドラの登録
  useEffect(() => {
    stream?.on("data", onData);
    stream?.on("metadata", onMetadata);
    stream?.on("status", onStatus);
    stream?.on("end", onEnd);

    return () => {
      stream?.removeListener("data", onData);
      stream?.removeListener("metadata", onMetadata);
      stream?.removeListener("status", onStatus);
      stream?.removeListener("end", onEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [stream]);

  return {
    startSubScribe,
  };
};
