import { VFC } from "react";
import { Box, Typography, Button } from "@mui/material";
import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const ConnectStatus: VFC<{
  remotePeerId: string;
  connectStatus: boolean;
  measure: boolean;
  setMeasure: (measure: boolean) => void;
}> = ({
  remotePeerId,
  connectStatus,
  measure,
  setMeasure,
}: {
  remotePeerId: string;
  connectStatus: boolean;
  measure: boolean;
  setMeasure: (measure: boolean) => void;
}) => {
  return (
    <Box>
      {connectStatus && (
        <StatusFlexBox>
          <Typography sx={{ fontSize: "24px" }} color="#4CAF50">
            {/* {!measure ? <CheckCircleOutlined /> : <LoadingOutlined />} */}
            <CheckCircleOutlined />
            <span style={{ margin: "0 16px 0 8px" }}>接続中</span>
          </Typography>
          <Box
            sx={{
              verticalAlign: "bottom",
              margin: "16px",
            }}
          >
            <Typography sx={{ fontSize: "14px" }} color="black">
              接続中のID：{remotePeerId}
            </Typography>
          </Box>

          {!measure ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setMeasure(true)}
              sx={{ height: "42px", backgroundColor: "#00A0E9" }}
            >
              測定を開始
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              sx={{ height: "42px" }}
              onClick={() => setMeasure(false)}
            >
              測定を終了
            </Button>
          )}
        </StatusFlexBox>
      )}
    </Box>
  );
};

const StatusFlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 32px;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    display: block;
    text-align: center;
  }
`;
