import { RpcError, StatusCode } from "grpc-web";
import { AuthorizationStreamInterceptor } from "./interceptor";
import { MessageServiceClient } from "./messaging/service/v1/MessageServiceClientPb";

/** app サービスのホスト名 */
export const appServiceHost =
  process.env.REACT_APP_EMMA_APP_URL ?? "http://localhost:9000";

/** messaging サービスのホスト名 */
const msgServiceHost =
  process.env.REACT_APP_EMMA_MESSAGING_URL ?? "http://localhost:9001";

/** MessagingService のクライアント */
export const MessagingClient = new MessageServiceClient(msgServiceHost, null, {
  streamInterceptors: [new AuthorizationStreamInterceptor()],
});

/**
 * Subscribe が終了したか
 *
 * @param RpcError gRPC エラー
 * @returns true: 終了した / false: 終了していない
 */
export const finishedSubscribe = ({ code, metadata }: RpcError) => {
  // 意図せずサーバーとの接続が切れた場合は StatusCode.UNKNOWN が返ってくる。
  // また、何らかの要因でサーバーがダウンしている場合は StatusCode.UNAVAILABLE が返る。
  if (code !== StatusCode.UNKNOWN && code !== StatusCode.UNAVAILABLE) {
    return false;
  }

  // サーバーとの接続が切れた or サーバーが使用不可 であればメタデータは返ってこない。
  if (Object.keys(metadata).length !== 0) return false;
  return true;
};
