import Peer, { MediaConnection } from "skyway-js";
import { useState, useEffect } from "react";
import { MainModal } from "src/layout/Main/Modal/const";

export const useMediaConnection = (
  peer: Peer | null,
  mediaStream: MediaStream | undefined,
  remotePeerId: string | null,
  connectStatus: boolean,
  setConnectStatus: (connectStatus: boolean) => void,
  setConnectOpen: (status: MainModal.ModalConnectStatus) => void
): {
  connectPeer: (peerId: string) => boolean;
  mediaConnection: MediaConnection | undefined;
  setMediaConnection: (mediaConnection: MediaConnection | undefined) => void;
} => {
  const [mediaConnection, setMediaConnection] = useState<
    MediaConnection | undefined
  >(undefined);

  useEffect(() => {
    if (peer && remotePeerId && mediaStream && !mediaConnection) {
      // p2p接続する
      try {
        const tmpMediaConnection = peer.call(remotePeerId, mediaStream);
        setMediaConnection(tmpMediaConnection);
        setConnectStatus(true);
        setConnectOpen(MainModal.ModalConnectStatus.MODAL_CONNECT_SUCCESS);
      } catch (e) {
        // ネットが切断されているかpeerが誤っています。
        setConnectOpen(MainModal.ModalConnectStatus.MODAL_NETWORK_ERROR);
      }
    } else {
      if (mediaConnection && mediaStream && connectStatus) {
        console.warn("replaceStream");
        mediaConnection.replaceStream(mediaStream);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [peer, remotePeerId, mediaStream, mediaConnection, connectStatus]);

  const connectPeer = (peerId: string): boolean => {
    if (peer && peerId && mediaStream && !mediaConnection) {
      // p2p接続する
      try {
        const tmpMediaConnection = peer.call(peerId, mediaStream);
        setTimeout(() => {
          if (!tmpMediaConnection.open) {
            setConnectOpen(MainModal.ModalConnectStatus.MODAL_CONNECT_ERROR);
          } else {
            setConnectOpen(MainModal.ModalConnectStatus.MODAL_CONNECT_SUCCESS);

            setConnectStatus(true);
          }
        }, 1000);
        setMediaConnection(tmpMediaConnection);
      } catch (e) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
  return {
    connectPeer,
    mediaConnection,
    setMediaConnection,
  };
};
