import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

export const Header = ({
  open,
  handleDrawerToggle,
}: {
  open: boolean;
  handleDrawerToggle: () => void;
}) => {
  return (
    <AppBar
      color={"inherit"}
      sx={{ left: open ? "260px" : "0px", boxShadow: 0 }}
    >
      <Toolbar>
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? "grey.200" : "grey.100",
            ml: { xs: 0, lg: -2 },
            borderRadius: "5px",
          }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
        <img
          style={{ marginLeft: "18px", height: "24px" }}
          alt="rura"
          src={`${process.env.PUBLIC_URL}/img/rura_logo_blue 2.svg`}
        />
        <Typography sx={{ ml: "13px", lineHeight: "24px" }}>
          ネットワーク状況確認ツール
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
