import { useLatest } from "react-use";
// import { RTCStatsMoment } from "rtcstats-wrapper";
import { DataConnection, MediaConnection } from "skyway-js";
import { LogFuncType, LogFuncDataType } from "src/types/networkLog";

type RTCInsightProps = {
  mediaConnection?: MediaConnection;
};

const getReport = async (connection?: DataConnection | MediaConnection) => {
  if (connection === undefined || !connection.open) {
    return null;
  }
  return await connection.getPeerConnection()?.getStats();
};

/**
 * WebRTC のデータを解析するための Hooks
 */
export const useRTCInsight = ({
  // 後で使うため一旦コメントアウト
  // dataConnection,
  mediaConnection,
}: RTCInsightProps): {
  getNetworkStats: LogFuncType;
} => {
  // 後で使うため一旦コメントアウト
  // const lastDataConnection = useLatest(dataConnection);
  // const dataMoment = useLatest(new RTCStatsMoment());

  const lastMediaConnection = useLatest(mediaConnection);
  // const mediaMoment = useLatest(new RTCStatsMoment());

  // 接客画面で受信された audioLevel をログに表示する
  const getNetworkStats = async () => {
    const d = await getReport(lastMediaConnection.current)
    if (d === null || d === undefined) {
      return null;
    }
    let receivedJitterBufferDelay: number = 0;
    let receivedBitrate: number = 0;
    let receivedVideoJitterBufferDelay: number = 0;
    let receivedVideoBitrate: number = 0;
    let audioLevel: number = 0;
    let sendJitterBufferDelay: number = 0;
    let sendBitrate: number = 0;
    let sendVideoJitterBufferDelay: number = 0;
    let sendVideoBitrate: number = 0;
    d.forEach((report) => {
      if (report.type === "inbound-rtp") { //受信
        // console.log("Packets received:", report.kind, report.packetsReceived);
        // console.log("Bytes received:", report.kind, report.bytesReceived);
        // console.log(
        //   "Jitter buffer delay:",
        //   report.kind,
        //   report.jitterBufferDelay
        // );
        if (report.kind === "audio") {
          receivedJitterBufferDelay = report.jitterBufferDelay;
          receivedBitrate = report.bytesReceived;
        } else if (report.kind === "video") {
          receivedVideoJitterBufferDelay = report.jitterBufferDelay;
          receivedVideoBitrate = report.bytesReceived;
        } 
        // その他の統計情報もここで取得可能
      } 
      else if (report.type === "remote-inbound-rtp") { //送信
        // console.log("remote-inbound-rtpd:", report);
        // console.log("Remote Packets received:", report.packetsReceived);
        // console.log("Remote Bytes received:", report.bytesReceived);
        if (report.kind === "audio") {
          // console.log("Remote Jitter Audio:", report.kind, report.jitter);
          sendJitterBufferDelay = report.jitter; 
        }
        if (report.kind === "video") {
          // console.log("Remote Jitter Video:", report.kind, report.jitter);
          sendVideoJitterBufferDelay = report.jitter;
        }
          
        // その他の統計情報もここで取得可能
      }
      else if (report.type === "media-source") {
        if (report.kind === "audio") {
          audioLevel = report.audioLevel;
          // console.log("Audio level:", report.audioLevel)
        };
      } 
      // else {
      //   console.log("report.type", report.type);
      // }
    });
    // try {
    //   mediaMoment.current.update(d);
    // } catch (e) {
    //   console.log("error")
    // }
    
    // console.warn("report", mediaMoment.current.report())
    let receive: LogFuncDataType = {
      audio: {
        audioLevel: audioLevel as number,
        jitterBufferDelay: receivedJitterBufferDelay as number,
        bitrate: receivedBitrate as number,
        fractionLost: 0,
      },
      video: {
        bitrate: receivedVideoBitrate,
        jitterBufferDelay: receivedVideoJitterBufferDelay,
      },
    };
    let send: LogFuncDataType = {
      audio: {
        audioLevel: audioLevel as number,
        jitterBufferDelay: sendJitterBufferDelay as number,
        bitrate: sendBitrate as number,
        fractionLost: 0 as number,
      },
      video: {
        bitrate: sendVideoBitrate,
        jitterBufferDelay: sendVideoJitterBufferDelay,
      },
    };
    // if (mediaMoment.current.report().receive){
    //   const { bitrate, jitterBufferDelay, fractionLost, audioLevel, videoBitrate, videoJitterBufferDelay } = getReportData(mediaMoment.current.report().receive)
    //   receive = {
    //     audio: {
    //       audioLevel: audioLevel as number,
    //       jitterBufferDelay: jitterBufferDelay as number,
    //       bitrate: bitrate as number,
    //       fractionLost: fractionLost as number,
    //     },
    //     video: {
    //       bitrate: videoBitrate,
    //       jitterBufferDelay: videoJitterBufferDelay,
    //     },
    //   }
    // }
    // if (mediaMoment.current.report().send){
    //   const { bitrate, jitterBufferDelay, fractionLost, audioLevel, videoBitrate, videoJitterBufferDelay } = getReportData(mediaMoment.current.report().send)
    //   send = {
    //     audio: {
    //       audioLevel: audioLevel as number,
    //       jitterBufferDelay: jitterBufferDelay as number,
    //       bitrate: bitrate as number,
    //       fractionLost: fractionLost as number,
    //     },
    //     video: {
    //       bitrate: videoBitrate,
    //       jitterBufferDelay: videoJitterBufferDelay,
    //     },
    //   }
    // }
    return {
      receive: receive,
      send: send
    };
    
  };

  const getReportData = (report: any) => {
    return {
      bitrate: report.audio.bitrate,
      jitterBufferDelay: report.audio.jitterBufferDelay ? report.audio.jitterBufferDelay : report.audio.jitter,
      fractionLost: report.audio.fractionLost,
      audioLevel: report.audio.fractionLost,
      videoBitrate: report.video.bitrate,
      videoJitterBufferDelay: report.video.jitterBufferDelay ? report.video.jitterBufferDelay : report.video.jitter
    }
  }

  return { getNetworkStats };
};
