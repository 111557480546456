import { VFC, useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "src/store/reducers/menu";
import { Header } from "src/layout/Header";
import { MainDrawer } from "src/layout/Drawer";
import { PageTitle } from "src/layout/Main/PageTitle";
import { ConnectStatus } from "src/layout/Main/ConnectStatus";
import { VideoView } from "src/layout/Main/VideoView";
import { ConnectArea } from "src/layout/Main/ConnectArea";
import { ConnectModal } from "src/layout/Main/Modal/ConnectModal";
import { MainModal } from "src/layout/Main/Modal/const";
import { NetworkInfo } from "src/layout/Main/NetworkInfo";
import { SpeedTest } from "src/layout/Main/SpeedTest";
import { LogBoard } from "src/layout/Main/LogBoard";
import { DeviceSetting } from "src/layout/Main/DeviceSetting";
import { useWebRtc } from "src/hooks/useWebRtc";
import styled from "styled-components";
import { BrowserAlert } from "src/layout/Components/BrowserAlert";

export const Main: VFC = () => {
  const { drawerOpen } = useSelector((state: any) => state.menu);
  const [open, setOpen] = useState<any>(drawerOpen);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  const [connectOpen, setConnectOpen] = useState<MainModal.ModalConnectStatus>(
    MainModal.ModalConnectStatus.MODAL_CLOSE
  );
  // TODO: ボタンを押した時の処理を相談する => 押した際に、ログは表示しっぱな気がする
  const [measure, setMeasure] = useState<boolean>(true);

  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const {
    audioInputs,
    videoInputs,
    setAudioInputId,
    setVideoInputId,
    viewAudioDeviceId,
    viewVideoDeviceId,
    peerId,
    anotherPeerId,
    connectStatus,
    connectPeer,
    mediaConnection,
    getStatus,
  } = useWebRtc(localVideoRef, remoteVideoRef, connectOpen, setConnectOpen);

  useEffect(() => {
    if (!measure && mediaConnection) {
      mediaConnection?.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [measure, mediaConnection]);

  return (
    <Box sx={{ display: "flex", margin: "auto" }} height="100%">
      <BrowserAlert active={false} />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <MainDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        sx={{
          padding: "64px 0",
        }}
        width="100%"
        height="100%"
      >
        <PageTitle />
        <Box
          style={{
            maxWidth: "1300px",
            margin: "0 auto",
            padding: "32px 24px",
          }}
        >
          <ConnectStatus
            remotePeerId={anotherPeerId ? anotherPeerId : ""}
            connectStatus={connectStatus}
            measure={measure}
            setMeasure={setMeasure}
          />
          <FlexBox>
            <FlexBoxOneTrirds>
              <DeviceSetting
                viewAudioDeviceId={viewAudioDeviceId}
                viewVideoDeviceId={viewVideoDeviceId}
                audioInputs={audioInputs}
                videoInputs={videoInputs}
                setAudioDeviceId={setAudioInputId}
                setVideoDeviceId={setVideoInputId}
              />
            </FlexBoxOneTrirds>
            <FlexBoxTwoThirds>
              <FlexBoxTwoThirdsDiv>
                <VideoView
                  videoRef={localVideoRef}
                  isLocal={true}
                  view={true}
                />
              </FlexBoxTwoThirdsDiv>
              <FlexBoxTwoThirdsDiv>
                <VideoView
                  videoRef={remoteVideoRef}
                  isLocal={false}
                  view={connectStatus}
                />
              </FlexBoxTwoThirdsDiv>
            </FlexBoxTwoThirds>
          </FlexBox>{" "}
          {!connectStatus ? (
            <ConnectArea
              setOpen={setConnectOpen}
              peerId={peerId}
              connectPeer={connectPeer}
            />
          ) : (
            <Box style={{ margin: "32px 0 0" }}>
              <Typography style={{ fontSize: "18px", margin: "0 0 16px" }}>
                測定結果：
              </Typography>
              <FlexBox>
                <FlexBoxOneTrirds>
                  <NetworkInfo mediaConnection={mediaConnection} />
                  <SpeedTest />
                </FlexBoxOneTrirds>
                <FlexBoxTwoThirds>
                  <LogBoard
                    mediaConnection={mediaConnection}
                    getStatus={getStatus}
                  />
                </FlexBoxTwoThirds>
              </FlexBox>
            </Box>
          )}
        </Box>
        <ConnectModal open={connectOpen} setOpen={setConnectOpen} />
      </Box>
    </Box>
  );
};

const FlexBox = styled.div`
  display: flex;
  @media (max-width: 920px) {
    display: block;
  }
`;
const FlexBoxOneTrirds = styled.div`
  width: calc(33.333% - 16px);
  margin: 0 16px 0 0;
  @media (max-width: 920px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;
const FlexBoxTwoThirds = styled.div`
  width: 66.666%;
  margin: 0 0 0 0;
  display: flex;
  @media (max-width: 920px) {
    width: 100%;
  }
  @media (max-width: 540px) {
    display: block;
  }
`;
const FlexBoxTwoThirdsDiv = styled.div`
  width: 50%;
  margin: 0 0 0 16px;
  align-items: stretch;
  &:first-of-type {
    margin: 0;
  }
  @media (max-width: 540px) {
    width: 100%;
    margin: 16px 0 0 0;
    &:first-of-type {
      margin: 0;
    }
  }
`;
