import { VFC } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { RequestUrl } from "src/interface/RequestUrl";
import styled from "styled-components";

export const DomainHttpCheck: VFC<{
  urls: RequestUrl[];
  requestDomains: () => void;
  emmaStatus: -1 | 0 | 1;
  storageStatus: -1 | 0 | 1;
  startSubScribe: () => void;
  startCheckStorage: () => void;
  pcType: string | null;
}> = ({
  urls,
  requestDomains,
  emmaStatus,
  storageStatus,
  startSubScribe,
  startCheckStorage,
  pcType,
}: {
  urls: RequestUrl[];
  requestDomains: () => void;
  emmaStatus: -1 | 0 | 1;
  storageStatus: -1 | 0 | 1;
  startSubScribe: () => void;
  startCheckStorage: () => void;
  pcType: string | null;
}) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        title={
          <Grid container justifyContent="space-between" alignItems={"center"}>
            <Typography>
              {"サーバー"}
              <Tooltip
                title={
                  pcType === "shop"
                    ? "RURAを安定利用する上で必要になるサーバーから、RURA用PCが属するインターネット環境にデータを問題なく送信・受信できるかを確認します。"
                    : "RURAを安定利用する上で必要になるサーバーから、遠隔スタッフ用PCが属するインターネット環境にデータを問題なく送信・受信できるかを確認します。"
                }
                placement="top"
              >
                <QuestionCircleOutlined style={{ marginLeft: "13px" }} />
              </Tooltip>
            </Typography>
            <Box>
              <Typography sx={{ fontSize: "14px", mt: "5px" }}>
                「確認する」をクリックしてください。
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ ml: "15px", backgroundColor: "#00A0E9" }}
                  onClick={() => {
                    requestDomains();
                    startSubScribe();
                    startCheckStorage();
                  }}
                >
                  確認する
                </Button>
              </Typography>
            </Box>
          </Grid>
        }
      />
      <CardContent style={{ padding: "0 16px" }}>
        {urls.map((urlData: RequestUrl, index: number) => (
          <FlexListContent key={index}>
            <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
              {urlData.title}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                padding: "16px 0",
                color:
                  urlData.status === "未確認"
                    ? "rgba(0, 0, 0, 0.38)"
                    : urlData.status === "接続完了"
                    ? "#4CAF50"
                    : urlData.status === "接続失敗"
                    ? "#EB1438"
                    : "#000000",
              }}
            >
              {urlData.status}
            </Typography>
          </FlexListContent>
        ))}
        <FlexListContent>
          <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
            RURA メディアストレージ
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "8px 0",
              color:
                storageStatus === 0
                  ? "rgba(0, 0, 0, 0.38)"
                  : emmaStatus === 1
                  ? "#4CAF50"
                  : "#EB1438",
            }}
          >
            {storageStatus === -1
              ? "接続失敗"
              : storageStatus === 0
              ? "未確認"
              : "接続完了"}
          </Typography>
        </FlexListContent>
        <FlexListContent>
          <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
            RURA 接客状態管理(EMMA)
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "8px 0",
              color:
                emmaStatus === 0
                  ? "rgba(0, 0, 0, 0.38)"
                  : emmaStatus === 1
                  ? "#4CAF50"
                  : "#EB1438",
            }}
          >
            {emmaStatus === -1
              ? "接続失敗"
              : emmaStatus === 0
              ? "未確認"
              : "接続完了"}
          </Typography>
        </FlexListContent>
      </CardContent>
    </Card>
  );
};
const FlexListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;
