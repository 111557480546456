// source: messaging/model/v1/event_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var messaging_model_v1_shop_pb = require('../../../messaging/model/v1/shop_pb.js');
goog.object.extend(proto, messaging_model_v1_shop_pb);
var messaging_model_v1_staff_pb = require('../../../messaging/model/v1/staff_pb.js');
goog.object.extend(proto, messaging_model_v1_staff_pb);
goog.exportSymbol('proto.messaging.model.v1.AcceptReceptionStart', null, global);
goog.exportSymbol('proto.messaging.model.v1.CheckTurnUsed', null, global);
goog.exportSymbol('proto.messaging.model.v1.ClearAndroidButtonNotice', null, global);
goog.exportSymbol('proto.messaging.model.v1.ConflictStaff', null, global);
goog.exportSymbol('proto.messaging.model.v1.DuplicateStaffLogin', null, global);
goog.exportSymbol('proto.messaging.model.v1.GoBackWaitRoom', null, global);
goog.exportSymbol('proto.messaging.model.v1.HeartBeat', null, global);
goog.exportSymbol('proto.messaging.model.v1.RebootAndroid', null, global);
goog.exportSymbol('proto.messaging.model.v1.RebootPc', null, global);
goog.exportSymbol('proto.messaging.model.v1.ShopDetection', null, global);
goog.exportSymbol('proto.messaging.model.v1.ShopDetection.Notification', null, global);
goog.exportSymbol('proto.messaging.model.v1.ShopDetection.Notification.Timing', null, global);
goog.exportSymbol('proto.messaging.model.v1.ShopLoginSuccess', null, global);
goog.exportSymbol('proto.messaging.model.v1.StartSubscribe', null, global);
goog.exportSymbol('proto.messaging.model.v1.UpdateShops', null, global);
goog.exportSymbol('proto.messaging.model.v1.UpdateStaffs', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.AcceptReceptionStart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.AcceptReceptionStart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.AcceptReceptionStart.displayName = 'proto.messaging.model.v1.AcceptReceptionStart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ShopLoginSuccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messaging.model.v1.ShopLoginSuccess.repeatedFields_, null);
};
goog.inherits(proto.messaging.model.v1.ShopLoginSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ShopLoginSuccess.displayName = 'proto.messaging.model.v1.ShopLoginSuccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.UpdateStaffs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messaging.model.v1.UpdateStaffs.repeatedFields_, null);
};
goog.inherits(proto.messaging.model.v1.UpdateStaffs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.UpdateStaffs.displayName = 'proto.messaging.model.v1.UpdateStaffs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.UpdateShops = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messaging.model.v1.UpdateShops.repeatedFields_, null);
};
goog.inherits(proto.messaging.model.v1.UpdateShops, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.UpdateShops.displayName = 'proto.messaging.model.v1.UpdateShops';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.GoBackWaitRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.GoBackWaitRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.GoBackWaitRoom.displayName = 'proto.messaging.model.v1.GoBackWaitRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ConflictStaff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.ConflictStaff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ConflictStaff.displayName = 'proto.messaging.model.v1.ConflictStaff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ClearAndroidButtonNotice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.ClearAndroidButtonNotice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ClearAndroidButtonNotice.displayName = 'proto.messaging.model.v1.ClearAndroidButtonNotice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.DuplicateStaffLogin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.DuplicateStaffLogin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.DuplicateStaffLogin.displayName = 'proto.messaging.model.v1.DuplicateStaffLogin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.RebootPc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.RebootPc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.RebootPc.displayName = 'proto.messaging.model.v1.RebootPc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.RebootAndroid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.RebootAndroid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.RebootAndroid.displayName = 'proto.messaging.model.v1.RebootAndroid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.HeartBeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.HeartBeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.HeartBeat.displayName = 'proto.messaging.model.v1.HeartBeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.StartSubscribe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.StartSubscribe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.StartSubscribe.displayName = 'proto.messaging.model.v1.StartSubscribe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ShopDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.ShopDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ShopDetection.displayName = 'proto.messaging.model.v1.ShopDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.ShopDetection.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messaging.model.v1.ShopDetection.Notification.repeatedFields_, null);
};
goog.inherits(proto.messaging.model.v1.ShopDetection.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.ShopDetection.Notification.displayName = 'proto.messaging.model.v1.ShopDetection.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.model.v1.CheckTurnUsed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.model.v1.CheckTurnUsed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.model.v1.CheckTurnUsed.displayName = 'proto.messaging.model.v1.CheckTurnUsed';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.AcceptReceptionStart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.AcceptReceptionStart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.AcceptReceptionStart.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    staff: (f = msg.getStaff()) && messaging_model_v1_staff_pb.Staff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.AcceptReceptionStart}
 */
proto.messaging.model.v1.AcceptReceptionStart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.AcceptReceptionStart;
  return proto.messaging.model.v1.AcceptReceptionStart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.AcceptReceptionStart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.AcceptReceptionStart}
 */
proto.messaging.model.v1.AcceptReceptionStart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    case 2:
      var value = new messaging_model_v1_staff_pb.Staff;
      reader.readMessage(value,messaging_model_v1_staff_pb.Staff.deserializeBinaryFromReader);
      msg.setStaff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.AcceptReceptionStart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.AcceptReceptionStart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.AcceptReceptionStart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStaff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      messaging_model_v1_staff_pb.Staff.serializeBinaryToWriter
    );
  }
};


/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.AcceptReceptionStart} returns this
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.setPcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Staff staff = 2;
 * @return {?proto.messaging.model.v1.Staff}
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.getStaff = function() {
  return /** @type{?proto.messaging.model.v1.Staff} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_staff_pb.Staff, 2));
};


/**
 * @param {?proto.messaging.model.v1.Staff|undefined} value
 * @return {!proto.messaging.model.v1.AcceptReceptionStart} returns this
*/
proto.messaging.model.v1.AcceptReceptionStart.prototype.setStaff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.AcceptReceptionStart} returns this
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.clearStaff = function() {
  return this.setStaff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.AcceptReceptionStart.prototype.hasStaff = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messaging.model.v1.ShopLoginSuccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ShopLoginSuccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ShopLoginSuccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopLoginSuccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    staffsList: jspb.Message.toObjectList(msg.getStaffsList(),
    messaging_model_v1_staff_pb.Staff.toObject, includeInstance),
    shop: (f = msg.getShop()) && messaging_model_v1_shop_pb.Shop.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ShopLoginSuccess}
 */
proto.messaging.model.v1.ShopLoginSuccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ShopLoginSuccess;
  return proto.messaging.model.v1.ShopLoginSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ShopLoginSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ShopLoginSuccess}
 */
proto.messaging.model.v1.ShopLoginSuccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_staff_pb.Staff;
      reader.readMessage(value,messaging_model_v1_staff_pb.Staff.deserializeBinaryFromReader);
      msg.addStaffs(value);
      break;
    case 2:
      var value = new messaging_model_v1_shop_pb.Shop;
      reader.readMessage(value,messaging_model_v1_shop_pb.Shop.deserializeBinaryFromReader);
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ShopLoginSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ShopLoginSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopLoginSuccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStaffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      messaging_model_v1_staff_pb.Staff.serializeBinaryToWriter
    );
  }
  f = message.getShop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      messaging_model_v1_shop_pb.Shop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Staff staffs = 1;
 * @return {!Array<!proto.messaging.model.v1.Staff>}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.getStaffsList = function() {
  return /** @type{!Array<!proto.messaging.model.v1.Staff>} */ (
    jspb.Message.getRepeatedWrapperField(this, messaging_model_v1_staff_pb.Staff, 1));
};


/**
 * @param {!Array<!proto.messaging.model.v1.Staff>} value
 * @return {!proto.messaging.model.v1.ShopLoginSuccess} returns this
*/
proto.messaging.model.v1.ShopLoginSuccess.prototype.setStaffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messaging.model.v1.Staff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messaging.model.v1.Staff}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.addStaffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messaging.model.v1.Staff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messaging.model.v1.ShopLoginSuccess} returns this
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.clearStaffsList = function() {
  return this.setStaffsList([]);
};


/**
 * optional Shop shop = 2;
 * @return {?proto.messaging.model.v1.Shop}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.getShop = function() {
  return /** @type{?proto.messaging.model.v1.Shop} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_shop_pb.Shop, 2));
};


/**
 * @param {?proto.messaging.model.v1.Shop|undefined} value
 * @return {!proto.messaging.model.v1.ShopLoginSuccess} returns this
*/
proto.messaging.model.v1.ShopLoginSuccess.prototype.setShop = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.ShopLoginSuccess} returns this
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.clearShop = function() {
  return this.setShop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.ShopLoginSuccess.prototype.hasShop = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messaging.model.v1.UpdateStaffs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.UpdateStaffs.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.UpdateStaffs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.UpdateStaffs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.UpdateStaffs.toObject = function(includeInstance, msg) {
  var f, obj = {
    staffsList: jspb.Message.toObjectList(msg.getStaffsList(),
    messaging_model_v1_staff_pb.Staff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.UpdateStaffs}
 */
proto.messaging.model.v1.UpdateStaffs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.UpdateStaffs;
  return proto.messaging.model.v1.UpdateStaffs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.UpdateStaffs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.UpdateStaffs}
 */
proto.messaging.model.v1.UpdateStaffs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_staff_pb.Staff;
      reader.readMessage(value,messaging_model_v1_staff_pb.Staff.deserializeBinaryFromReader);
      msg.addStaffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.UpdateStaffs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.UpdateStaffs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.UpdateStaffs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.UpdateStaffs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStaffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      messaging_model_v1_staff_pb.Staff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Staff staffs = 1;
 * @return {!Array<!proto.messaging.model.v1.Staff>}
 */
proto.messaging.model.v1.UpdateStaffs.prototype.getStaffsList = function() {
  return /** @type{!Array<!proto.messaging.model.v1.Staff>} */ (
    jspb.Message.getRepeatedWrapperField(this, messaging_model_v1_staff_pb.Staff, 1));
};


/**
 * @param {!Array<!proto.messaging.model.v1.Staff>} value
 * @return {!proto.messaging.model.v1.UpdateStaffs} returns this
*/
proto.messaging.model.v1.UpdateStaffs.prototype.setStaffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messaging.model.v1.Staff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messaging.model.v1.Staff}
 */
proto.messaging.model.v1.UpdateStaffs.prototype.addStaffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messaging.model.v1.Staff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messaging.model.v1.UpdateStaffs} returns this
 */
proto.messaging.model.v1.UpdateStaffs.prototype.clearStaffsList = function() {
  return this.setStaffsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messaging.model.v1.UpdateShops.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.UpdateShops.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.UpdateShops.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.UpdateShops} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.UpdateShops.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopsList: jspb.Message.toObjectList(msg.getShopsList(),
    messaging_model_v1_shop_pb.Shop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.UpdateShops}
 */
proto.messaging.model.v1.UpdateShops.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.UpdateShops;
  return proto.messaging.model.v1.UpdateShops.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.UpdateShops} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.UpdateShops}
 */
proto.messaging.model.v1.UpdateShops.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_shop_pb.Shop;
      reader.readMessage(value,messaging_model_v1_shop_pb.Shop.deserializeBinaryFromReader);
      msg.addShops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.UpdateShops.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.UpdateShops.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.UpdateShops} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.UpdateShops.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      messaging_model_v1_shop_pb.Shop.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Shop shops = 1;
 * @return {!Array<!proto.messaging.model.v1.Shop>}
 */
proto.messaging.model.v1.UpdateShops.prototype.getShopsList = function() {
  return /** @type{!Array<!proto.messaging.model.v1.Shop>} */ (
    jspb.Message.getRepeatedWrapperField(this, messaging_model_v1_shop_pb.Shop, 1));
};


/**
 * @param {!Array<!proto.messaging.model.v1.Shop>} value
 * @return {!proto.messaging.model.v1.UpdateShops} returns this
*/
proto.messaging.model.v1.UpdateShops.prototype.setShopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messaging.model.v1.Shop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messaging.model.v1.Shop}
 */
proto.messaging.model.v1.UpdateShops.prototype.addShops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messaging.model.v1.Shop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messaging.model.v1.UpdateShops} returns this
 */
proto.messaging.model.v1.UpdateShops.prototype.clearShopsList = function() {
  return this.setShopsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.GoBackWaitRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.GoBackWaitRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.GoBackWaitRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.GoBackWaitRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.GoBackWaitRoom}
 */
proto.messaging.model.v1.GoBackWaitRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.GoBackWaitRoom;
  return proto.messaging.model.v1.GoBackWaitRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.GoBackWaitRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.GoBackWaitRoom}
 */
proto.messaging.model.v1.GoBackWaitRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.GoBackWaitRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.GoBackWaitRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.GoBackWaitRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.GoBackWaitRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.GoBackWaitRoom.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.GoBackWaitRoom} returns this
 */
proto.messaging.model.v1.GoBackWaitRoom.prototype.setPcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ConflictStaff.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ConflictStaff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ConflictStaff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ConflictStaff.toObject = function(includeInstance, msg) {
  var f, obj = {
    staffId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ConflictStaff}
 */
proto.messaging.model.v1.ConflictStaff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ConflictStaff;
  return proto.messaging.model.v1.ConflictStaff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ConflictStaff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ConflictStaff}
 */
proto.messaging.model.v1.ConflictStaff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ConflictStaff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ConflictStaff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ConflictStaff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ConflictStaff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStaffId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string staff_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.ConflictStaff.prototype.getStaffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ConflictStaff} returns this
 */
proto.messaging.model.v1.ConflictStaff.prototype.setStaffId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ClearAndroidButtonNotice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ClearAndroidButtonNotice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ClearAndroidButtonNotice}
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ClearAndroidButtonNotice;
  return proto.messaging.model.v1.ClearAndroidButtonNotice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ClearAndroidButtonNotice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ClearAndroidButtonNotice}
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ClearAndroidButtonNotice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ClearAndroidButtonNotice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ClearAndroidButtonNotice} returns this
 */
proto.messaging.model.v1.ClearAndroidButtonNotice.prototype.setPcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.DuplicateStaffLogin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.DuplicateStaffLogin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.DuplicateStaffLogin.toObject = function(includeInstance, msg) {
  var f, obj = {
    staff: (f = msg.getStaff()) && messaging_model_v1_staff_pb.Staff.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin}
 */
proto.messaging.model.v1.DuplicateStaffLogin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.DuplicateStaffLogin;
  return proto.messaging.model.v1.DuplicateStaffLogin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.DuplicateStaffLogin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin}
 */
proto.messaging.model.v1.DuplicateStaffLogin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_staff_pb.Staff;
      reader.readMessage(value,messaging_model_v1_staff_pb.Staff.deserializeBinaryFromReader);
      msg.setStaff(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.DuplicateStaffLogin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.DuplicateStaffLogin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.DuplicateStaffLogin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStaff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      messaging_model_v1_staff_pb.Staff.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Staff staff = 1;
 * @return {?proto.messaging.model.v1.Staff}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.getStaff = function() {
  return /** @type{?proto.messaging.model.v1.Staff} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_staff_pb.Staff, 1));
};


/**
 * @param {?proto.messaging.model.v1.Staff|undefined} value
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin} returns this
*/
proto.messaging.model.v1.DuplicateStaffLogin.prototype.setStaff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin} returns this
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.clearStaff = function() {
  return this.setStaff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.hasStaff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin} returns this
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.setToken = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.model.v1.DuplicateStaffLogin} returns this
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.clearToken = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.DuplicateStaffLogin.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.RebootPc.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.RebootPc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.RebootPc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.RebootPc.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isCold: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.RebootPc}
 */
proto.messaging.model.v1.RebootPc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.RebootPc;
  return proto.messaging.model.v1.RebootPc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.RebootPc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.RebootPc}
 */
proto.messaging.model.v1.RebootPc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.RebootPc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.RebootPc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.RebootPc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.RebootPc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsCold();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.RebootPc.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.RebootPc} returns this
 */
proto.messaging.model.v1.RebootPc.prototype.setPcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_cold = 2;
 * @return {boolean}
 */
proto.messaging.model.v1.RebootPc.prototype.getIsCold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messaging.model.v1.RebootPc} returns this
 */
proto.messaging.model.v1.RebootPc.prototype.setIsCold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.RebootAndroid.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.RebootAndroid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.RebootAndroid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.RebootAndroid.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.RebootAndroid}
 */
proto.messaging.model.v1.RebootAndroid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.RebootAndroid;
  return proto.messaging.model.v1.RebootAndroid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.RebootAndroid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.RebootAndroid}
 */
proto.messaging.model.v1.RebootAndroid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.RebootAndroid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.RebootAndroid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.RebootAndroid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.RebootAndroid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pc_id = 1;
 * @return {string}
 */
proto.messaging.model.v1.RebootAndroid.prototype.getPcId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.RebootAndroid} returns this
 */
proto.messaging.model.v1.RebootAndroid.prototype.setPcId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.HeartBeat.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.HeartBeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.HeartBeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.HeartBeat.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.HeartBeat}
 */
proto.messaging.model.v1.HeartBeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.HeartBeat;
  return proto.messaging.model.v1.HeartBeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.HeartBeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.HeartBeat}
 */
proto.messaging.model.v1.HeartBeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.HeartBeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.HeartBeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.HeartBeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.HeartBeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.StartSubscribe.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.StartSubscribe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.StartSubscribe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.StartSubscribe.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.StartSubscribe}
 */
proto.messaging.model.v1.StartSubscribe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.StartSubscribe;
  return proto.messaging.model.v1.StartSubscribe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.StartSubscribe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.StartSubscribe}
 */
proto.messaging.model.v1.StartSubscribe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.StartSubscribe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.StartSubscribe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.StartSubscribe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.StartSubscribe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ShopDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ShopDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ShopDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    shop: (f = msg.getShop()) && messaging_model_v1_shop_pb.Shop.toObject(includeInstance, f),
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notification: (f = msg.getNotification()) && proto.messaging.model.v1.ShopDetection.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ShopDetection}
 */
proto.messaging.model.v1.ShopDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ShopDetection;
  return proto.messaging.model.v1.ShopDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ShopDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ShopDetection}
 */
proto.messaging.model.v1.ShopDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_shop_pb.Shop;
      reader.readMessage(value,messaging_model_v1_shop_pb.Shop.deserializeBinaryFromReader);
      msg.setShop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = new proto.messaging.model.v1.ShopDetection.Notification;
      reader.readMessage(value,proto.messaging.model.v1.ShopDetection.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ShopDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ShopDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ShopDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShop();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      messaging_model_v1_shop_pb.Shop.serializeBinaryToWriter
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.messaging.model.v1.ShopDetection.Notification.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messaging.model.v1.ShopDetection.Notification.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.ShopDetection.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.ShopDetection.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopDetection.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notificationAt: (f = msg.getNotificationAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    timing: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.ShopDetection.Notification}
 */
proto.messaging.model.v1.ShopDetection.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.ShopDetection.Notification;
  return proto.messaging.model.v1.ShopDetection.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.ShopDetection.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.ShopDetection.Notification}
 */
proto.messaging.model.v1.ShopDetection.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNotificationAt(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserGroupIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {!proto.messaging.model.v1.ShopDetection.Notification.Timing} */ (reader.readEnum());
      msg.setTiming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.ShopDetection.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.ShopDetection.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.ShopDetection.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotificationAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserGroupIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getTiming();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.messaging.model.v1.ShopDetection.Notification.Timing = {
  TIMING_UNSPECIFIED: 0,
  TIMING_NORMAL: 1,
  TIMING_CALL_STAFF: 2
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp notification_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.getNotificationAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
*/
proto.messaging.model.v1.ShopDetection.Notification.prototype.setNotificationAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.clearNotificationAt = function() {
  return this.setNotificationAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.hasNotificationAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int64 user_group_ids = 3;
 * @return {!Array<number>}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.getUserGroupIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.setUserGroupIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.addUserGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.clearUserGroupIdsList = function() {
  return this.setUserGroupIdsList([]);
};


/**
 * optional Timing timing = 4;
 * @return {!proto.messaging.model.v1.ShopDetection.Notification.Timing}
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.getTiming = function() {
  return /** @type {!proto.messaging.model.v1.ShopDetection.Notification.Timing} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.messaging.model.v1.ShopDetection.Notification.Timing} value
 * @return {!proto.messaging.model.v1.ShopDetection.Notification} returns this
 */
proto.messaging.model.v1.ShopDetection.Notification.prototype.setTiming = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Shop shop = 1;
 * @return {?proto.messaging.model.v1.Shop}
 */
proto.messaging.model.v1.ShopDetection.prototype.getShop = function() {
  return /** @type{?proto.messaging.model.v1.Shop} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_shop_pb.Shop, 1));
};


/**
 * @param {?proto.messaging.model.v1.Shop|undefined} value
 * @return {!proto.messaging.model.v1.ShopDetection} returns this
*/
proto.messaging.model.v1.ShopDetection.prototype.setShop = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.ShopDetection} returns this
 */
proto.messaging.model.v1.ShopDetection.prototype.clearShop = function() {
  return this.setShop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.ShopDetection.prototype.hasShop = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.messaging.model.v1.ShopDetection.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.model.v1.ShopDetection} returns this
 */
proto.messaging.model.v1.ShopDetection.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Notification notification = 3;
 * @return {?proto.messaging.model.v1.ShopDetection.Notification}
 */
proto.messaging.model.v1.ShopDetection.prototype.getNotification = function() {
  return /** @type{?proto.messaging.model.v1.ShopDetection.Notification} */ (
    jspb.Message.getWrapperField(this, proto.messaging.model.v1.ShopDetection.Notification, 3));
};


/**
 * @param {?proto.messaging.model.v1.ShopDetection.Notification|undefined} value
 * @return {!proto.messaging.model.v1.ShopDetection} returns this
*/
proto.messaging.model.v1.ShopDetection.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.model.v1.ShopDetection} returns this
 */
proto.messaging.model.v1.ShopDetection.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.model.v1.ShopDetection.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.model.v1.CheckTurnUsed.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.model.v1.CheckTurnUsed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.model.v1.CheckTurnUsed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.CheckTurnUsed.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.model.v1.CheckTurnUsed}
 */
proto.messaging.model.v1.CheckTurnUsed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.model.v1.CheckTurnUsed;
  return proto.messaging.model.v1.CheckTurnUsed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.model.v1.CheckTurnUsed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.model.v1.CheckTurnUsed}
 */
proto.messaging.model.v1.CheckTurnUsed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.model.v1.CheckTurnUsed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.model.v1.CheckTurnUsed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.model.v1.CheckTurnUsed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.model.v1.CheckTurnUsed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.messaging.model.v1);
