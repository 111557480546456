import { Drawer } from "@mui/material";
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
export const MainDrawer = ({
  open,
  handleDrawerToggle,
}: {
  open: boolean;
  handleDrawerToggle: () => void;
}) => {
  const drawerWidth = 260;
  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="left"
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
      sx={{
        width: open ? drawerWidth : 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <DrawerHeader open={open} />
      <DrawerContent />
    </Drawer>
  );
};
