import { useCallback, useState } from "react";
import { useCounter } from "react-use";

export type BackoffPayload = {
  /** 初期遅延ミリ秒 */
  initialDelayMs?: number;

  /** 最大遅延ミリ秒 */
  maxDelayMs?: number;
};

export type BackoffResult = {
  /** 一定時間待機します。*/
  wait: () => Promise<void>;

  /** 遅延秒数とリトライ回数をリセットします。 */
  reset: () => void;

  /** wait が呼び出された回数 */
  retry: number;
};

/** デフォルト初期遅延ミリ秒 */
const DEFAULT_INIT_DELAY = 200;

/** ジッタの生成処理 */
const jitter = () => Math.floor(Math.random() * 1000);

/**
 * Exponential Backoff に準じた待機処理を行う関数を生成します。
 */
export const useBackoff = ({
  initialDelayMs = DEFAULT_INIT_DELAY,
  maxDelayMs,
}: BackoffPayload): BackoffResult => {
  const [delay, setDelay] = useState(initialDelayMs);
  const [retry, { inc, set }] = useCounter(0);

  // 待機処理
  // 指数関数的に待機秒数が伸びていきます。
  // なお、遅延の増加パターンが一定にならないよう、ジッタを含めるようにしています。
  const wait = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, delay));
    setDelay((prev) => {
      const next = prev * 2;
      if (maxDelayMs !== undefined && next >= maxDelayMs) {
        return maxDelayMs;
      }
      return next + jitter();
    });
    inc();
  }, [inc, delay, maxDelayMs]);

  const reset = useCallback(() => {
    setDelay(initialDelayMs);
    set(0);
  }, [initialDelayMs, set]);

  return { wait, reset, retry };
};
