import { VFC } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { RequestUrl } from "src/interface/RequestUrl";
import styled from "styled-components";

export const DomainBrowserCheck: VFC<{
  urls: RequestUrl[];
  openPopup: (url: string) => void;
  updateCheck: (index: number, flg: boolean) => void;
  pcType: string | null;
}> = ({
  urls,
  openPopup,
  updateCheck,
  pcType,
}: {
  urls: RequestUrl[];
  openPopup: (url: string) => void;
  updateCheck: (index: number, flg: boolean) => void;
  pcType: string | null;
}) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        title={
          <Grid
            style={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ margin: "0 16px 0 0" }}>
              {"ドメイン接続"}
              <Tooltip
                title={
                  pcType === "shop"
                    ? "弊社から店舗PCの遠隔サポートをする際に利用する通信が可能かどうかを確認します。"
                    : "遠隔スタッフ用PCから店舗PCの詳細設定サイトやサポートサイトへのアクセスが問題なくできるかを確認します。"
                }
                placement="top"
              >
                <QuestionCircleOutlined style={{ marginLeft: "13px" }} />
              </Tooltip>
            </Typography>
            <Typography
              sx={{ fontSize: "12px", maxWidth: "440px", padding: "8px 0" }}
            >
              各ドメインの「確認する」をクリックしてください。適切に接続できた場合、別タブでページが表示されますので「成功」にチェックを入れてください。
            </Typography>
          </Grid>
        }
      />
      <CardContent style={{ padding: "0 16px" }}>
        {urls.map((urlData: RequestUrl, index: number) => (
          <FlexListContent key={index}>
            <Typography sx={{ fontSize: "14px", padding: "8px 0" }}>
              {urlData.title}
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "8px 0",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <input
                  width="15px"
                  type="checkbox"
                  onChange={() => {
                    updateCheck(index, true);
                  }}
                />
                <span style={{ marginLeft: "8px", fontSize: "14px" }}>
                  成功
                </span>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <input
                  width="15px"
                  type="checkbox"
                  style={{ marginLeft: "27px" }}
                  onChange={() => {
                    updateCheck(index, false);
                  }}
                />
                <span style={{ marginLeft: "8px", fontSize: "14px" }}>
                  失敗
                </span>
                <Button
                  onClick={() => {
                    openPopup(urlData.url);
                  }}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "30px",
                    ml: "25px",
                    backgroundColor: "#00A0E9",
                  }}
                >
                  確認する
                </Button>
              </Box>
            </Box>
          </FlexListContent>
        ))}
      </CardContent>
    </Card>
  );
};

const FlexListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;
