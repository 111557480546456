import * as grpc from "grpc-web";

/** ローカルストレージからトークンを取得します。 */
const getToken = () => localStorage.getItem("token");

/**
 * メタデータに認証トークンを設定するための UnaryInterceptor です。
 */
export class AuthorizationUnaryInterceptor
  implements grpc.UnaryInterceptor<unknown, unknown>
{
  intercept(
    request: grpc.Request<unknown, unknown>,
    invoker: (
      request: grpc.Request<unknown, unknown>
    ) => Promise<grpc.UnaryResponse<unknown, unknown>>
  ): Promise<grpc.UnaryResponse<unknown, unknown>> {
    const token = getToken();
    if (token) {
      const metadata = request.getMetadata();
      metadata["authorization"] = `Bearer ${token}`;
    }
    return invoker(request);
  }
}

/**
 * メタデータに PeerId を設定するための UnaryInterceptor です。
 */
export class PeerIdUnaryInterceptor
  implements grpc.UnaryInterceptor<unknown, unknown>
{
  constructor(private peerId: string) {}
  intercept(
    request: grpc.Request<unknown, unknown>,
    invoker: (
      request: grpc.Request<unknown, unknown>
    ) => Promise<grpc.UnaryResponse<unknown, unknown>>
  ): Promise<grpc.UnaryResponse<unknown, unknown>> {
    const token = getToken();
    if (token) {
      const metadata = request.getMetadata();
      metadata["x-peer-id"] = this.peerId;
    }
    return invoker(request);
  }
}

/**
 * メタデータに認証トークンを設定するための StreamInterceptor です。
 */
export class AuthorizationStreamInterceptor
  implements grpc.StreamInterceptor<unknown, unknown>
{
  intercept(
    request: grpc.Request<unknown, unknown>,
    invoker: (
      request: grpc.Request<unknown, unknown>
    ) => grpc.ClientReadableStream<unknown>
  ): grpc.ClientReadableStream<unknown> {
    const token = getToken();
    if (token) {
      const metadata = request.getMetadata();
      metadata["authorization"] = `Bearer ${token}`;
    }
    return invoker(request);
  }
}
