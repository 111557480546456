// source: messaging/service/v1/message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var messaging_model_v1_event_pb = require('../../../messaging/model/v1/event_pb.js');
goog.object.extend(proto, messaging_model_v1_event_pb);
goog.exportSymbol('proto.messaging.service.v1.PublishRequest', null, global);
goog.exportSymbol('proto.messaging.service.v1.PublishResponse', null, global);
goog.exportSymbol('proto.messaging.service.v1.SubscribeRequest', null, global);
goog.exportSymbol('proto.messaging.service.v1.SubscribeRequest.IdCase', null, global);
goog.exportSymbol('proto.messaging.service.v1.SubscribeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.service.v1.PublishRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messaging.service.v1.PublishRequest.repeatedFields_, null);
};
goog.inherits(proto.messaging.service.v1.PublishRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.service.v1.PublishRequest.displayName = 'proto.messaging.service.v1.PublishRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.service.v1.PublishResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.service.v1.PublishResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.service.v1.PublishResponse.displayName = 'proto.messaging.service.v1.PublishResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.service.v1.SubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.messaging.service.v1.SubscribeRequest.oneofGroups_);
};
goog.inherits(proto.messaging.service.v1.SubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.service.v1.SubscribeRequest.displayName = 'proto.messaging.service.v1.SubscribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messaging.service.v1.SubscribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messaging.service.v1.SubscribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messaging.service.v1.SubscribeResponse.displayName = 'proto.messaging.service.v1.SubscribeResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messaging.service.v1.PublishRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.service.v1.PublishRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.service.v1.PublishRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.service.v1.PublishRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.PublishRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    messaging_model_v1_event_pb.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.service.v1.PublishRequest}
 */
proto.messaging.service.v1.PublishRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.service.v1.PublishRequest;
  return proto.messaging.service.v1.PublishRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.service.v1.PublishRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.service.v1.PublishRequest}
 */
proto.messaging.service.v1.PublishRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_event_pb.Event;
      reader.readMessage(value,messaging_model_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.service.v1.PublishRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.service.v1.PublishRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.service.v1.PublishRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.PublishRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      messaging_model_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated messaging.model.v1.Event events = 1;
 * @return {!Array<!proto.messaging.model.v1.Event>}
 */
proto.messaging.service.v1.PublishRequest.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.messaging.model.v1.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, messaging_model_v1_event_pb.Event, 1));
};


/**
 * @param {!Array<!proto.messaging.model.v1.Event>} value
 * @return {!proto.messaging.service.v1.PublishRequest} returns this
*/
proto.messaging.service.v1.PublishRequest.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messaging.model.v1.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messaging.model.v1.Event}
 */
proto.messaging.service.v1.PublishRequest.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messaging.model.v1.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messaging.service.v1.PublishRequest} returns this
 */
proto.messaging.service.v1.PublishRequest.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.service.v1.PublishResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.service.v1.PublishResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.service.v1.PublishResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.PublishResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.service.v1.PublishResponse}
 */
proto.messaging.service.v1.PublishResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.service.v1.PublishResponse;
  return proto.messaging.service.v1.PublishResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.service.v1.PublishResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.service.v1.PublishResponse}
 */
proto.messaging.service.v1.PublishResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.service.v1.PublishResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.service.v1.PublishResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.service.v1.PublishResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.PublishResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.messaging.service.v1.SubscribeRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.messaging.service.v1.SubscribeRequest.IdCase = {
  ID_NOT_SET: 0,
  STAFF_ID: 2,
  SHOP_ID: 3
};

/**
 * @return {proto.messaging.service.v1.SubscribeRequest.IdCase}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.getIdCase = function() {
  return /** @type {proto.messaging.service.v1.SubscribeRequest.IdCase} */(jspb.Message.computeOneofCase(this, proto.messaging.service.v1.SubscribeRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.service.v1.SubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.service.v1.SubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.SubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    staffId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shopId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    peerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.service.v1.SubscribeRequest}
 */
proto.messaging.service.v1.SubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.service.v1.SubscribeRequest;
  return proto.messaging.service.v1.SubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.service.v1.SubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.service.v1.SubscribeRequest}
 */
proto.messaging.service.v1.SubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.service.v1.SubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.service.v1.SubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.SubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPeerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string staff_id = 2;
 * @return {string}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.getStaffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.setStaffId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.messaging.service.v1.SubscribeRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.clearStaffId = function() {
  return jspb.Message.setOneofField(this, 2, proto.messaging.service.v1.SubscribeRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.hasStaffId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string shop_id = 3;
 * @return {string}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.setShopId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.messaging.service.v1.SubscribeRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.clearShopId = function() {
  return jspb.Message.setOneofField(this, 3, proto.messaging.service.v1.SubscribeRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.hasShopId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string peer_id = 4;
 * @return {string}
 */
proto.messaging.service.v1.SubscribeRequest.prototype.getPeerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messaging.service.v1.SubscribeRequest} returns this
 */
proto.messaging.service.v1.SubscribeRequest.prototype.setPeerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messaging.service.v1.SubscribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messaging.service.v1.SubscribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messaging.service.v1.SubscribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.SubscribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && messaging_model_v1_event_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messaging.service.v1.SubscribeResponse}
 */
proto.messaging.service.v1.SubscribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messaging.service.v1.SubscribeResponse;
  return proto.messaging.service.v1.SubscribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messaging.service.v1.SubscribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messaging.service.v1.SubscribeResponse}
 */
proto.messaging.service.v1.SubscribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_model_v1_event_pb.Event;
      reader.readMessage(value,messaging_model_v1_event_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messaging.service.v1.SubscribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messaging.service.v1.SubscribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messaging.service.v1.SubscribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messaging.service.v1.SubscribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      messaging_model_v1_event_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional messaging.model.v1.Event event = 1;
 * @return {?proto.messaging.model.v1.Event}
 */
proto.messaging.service.v1.SubscribeResponse.prototype.getEvent = function() {
  return /** @type{?proto.messaging.model.v1.Event} */ (
    jspb.Message.getWrapperField(this, messaging_model_v1_event_pb.Event, 1));
};


/**
 * @param {?proto.messaging.model.v1.Event|undefined} value
 * @return {!proto.messaging.service.v1.SubscribeResponse} returns this
*/
proto.messaging.service.v1.SubscribeResponse.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messaging.service.v1.SubscribeResponse} returns this
 */
proto.messaging.service.v1.SubscribeResponse.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messaging.service.v1.SubscribeResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.messaging.service.v1);
