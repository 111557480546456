import { VFC, ReactNode, useState } from "react";
// ==============================|| DRAWER HEADER - STYLED ||============================== //
type Props = {
  children: ReactNode;
  style?: any;
};

export const IconHover: VFC<Props> = ({ children, style }: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <div
      style={{
        ...style,
        backgroundColor: hover ? "rgba(0,0,0,0.08)" : "#ffffff",
        display: "inline-block",
        padding: "3px",
        textAlign: "center",
        borderRadius: "3px",
        cursor: "pointer",
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </div>
  );
};
