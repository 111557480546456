import { useState, useEffect } from "react";
import { RequestUrl } from "src/interface/RequestUrl";
import { requestDomainCheck } from "src/api/domainCheckRequest";

export const useRequest = (
  urls: RequestUrl[]
): {
  domainUrls: RequestUrl[];
  setDomainUrls: (requestUrls: RequestUrl[]) => void;
  requestDomains: () => void;
} => {
  const [domainUrls, setDomainUrls] = useState<RequestUrl[]>(
    urls.filter(
      (requestUrlData: RequestUrl) => requestUrlData.checkType === "fetch"
    )
  );
  const [isRender, setRender] = useState<boolean>(false);

  useEffect(() => {
    console.warn("domainUrls", domainUrls);
  }, [domainUrls]);

  const requestDomains = () => {
    console.warn("requestDomains", domainUrls);
    let count = 0;
    domainUrls.forEach((domain: RequestUrl, index: number) => {
      console.warn("requestDomainCheck", domain.url, index);
      const result = requestDomainCheck(domain.url);
      result
        .then((res: any) => {
          if (res) {
            console.warn("res status", domain.url, res[0]);
            if (res[0] === 200) {
              updateDomains(index, "接続完了");
            } else {
              updateDomains(index, "接続失敗");
            }
          }
        })
        .finally(() => {
          count += 1;
          if (count >= domainUrls.length) setRender(!isRender);
        });
    });
  };

  const updateDomains = (
    index: number,
    statusText: "接続確認中" | "接続完了" | "接続失敗" | "未確認"
  ) => {
    const tmpDomains = domainUrls;
    tmpDomains[index].status = statusText;
    setDomainUrls(tmpDomains);
  };

  return { domainUrls, setDomainUrls, requestDomains };
};
