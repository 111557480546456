import { VFC } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const PageTitle: VFC<{ pcType: string | null }> = ({
  pcType,
}: {
  pcType: string | null;
}) => {
  const navigate = useNavigate();
  return (
    <Box style={{ borderBottom: "solid 1px #ddd", width: "100%" }}>
      <Stack direction="row" justifyContent="flex-start" alignItems={"center"}>
        <Typography sx={{ fontSize: "16px", padding: "0 24px" }} color="black">
          ドメイン接続
        </Typography>

        <PageTitleButton
          style={{
            color: pcType === "shop" ? "black" : "grey",
            borderColor: pcType === "shop" ? "#00A0E9" : "transparent",
          }}
          onClick={() => {
            navigate("/domain?pc_type=shop");
            document.location.reload();
          }}
        >
          RURA用PC
        </PageTitleButton>
        <PageTitleButton
          style={{
            color: pcType === "worker" ? "black" : "grey",
            borderColor: pcType === "worker" ? "#00A0E9" : "transparent",
          }}
          onClick={() => {
            navigate("/domain?pc_type=worker");
            document.location.reload();
          }}
        >
          遠隔スタッフ用PC
        </PageTitleButton>
      </Stack>
    </Box>
  );
};

const PageTitleButton = styled.button`
  padding: 12px 16px;
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  border-bottom: solid 2px transparent;
  background-color: transparent;
  &:hover {
    color: black !important;
  }
`;
