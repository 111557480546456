// project import
import { Box, Typography } from "@mui/material";
import {
  ApiOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import SimpleBar from "src/components/ThirdParty/SimpleBar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// ==============================|| DRAWER CONTENT ||============================== //
const DrawerContent = () => {
  const navigate = useNavigate();

  return (
    <SimpleBar>
      <Box>
        <NavButton onClick={() => navigate("/")}>
          <ApiOutlined />
          <Typography
            color="black"
            align="center"
            sx={{ pl: 2, lineHeight: "20px" }}
          >
            ネットワーク接続
          </Typography>
        </NavButton>
        <NavButton onClick={() => navigate("/domain?pc_type=shop")}>
          <GlobalOutlined />
          <Typography
            color="black"
            align="center"
            sx={{ pl: 2, lineHeight: "20px" }}
          >
            ドメイン接続
          </Typography>
        </NavButton>
        <NavButton
          onClick={() => {
            window.open("https://support.timeleap-rura.com/");
          }}
        >
          <QuestionCircleOutlined />
          <Typography
            color="black"
            align="center"
            sx={{ pl: 2, lineHeight: "20px" }}
          >
            RURA サポートサイト
          </Typography>
        </NavButton>
        <NavButton
          onClick={() => {
            window.open(process.env.REACT_APP_PRIVACY_POLICY_URL);
          }}
        >
          <ProfileOutlined />
          <Typography
            color="black"
            align="center"
            sx={{ pl: 2, lineHeight: "20px" }}
          >
            プライバシーポリシー
          </Typography>
        </NavButton>
      </Box>
    </SimpleBar>
  );
};

export default DrawerContent;

const NavButton = styled.button`
  /* This renders the buttons above... Edit me! */
  display: flex;
  width: 100%;
  padding: 12px 16px;
  border: none;
  align-items: center;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: #eeeeee;
  }
  &:active {
    background-color: #dddddd;
  }
`;
