import { VFC, useState, useRef } from "react";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tooltip,
  InputLabel,
} from "@mui/material";
import {
  VideoCameraOutlined,
  AudioOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { DeviceType } from "src/types/device";
import SelectStyled from "src/layout/Components/SelectStyle";

export const DeviceSetting: VFC<{
  viewAudioDeviceId: string | undefined;
  viewVideoDeviceId: string | undefined;
  audioInputs: DeviceType[];
  videoInputs: DeviceType[];
  setAudioDeviceId: (audio: string | undefined) => void;
  setVideoDeviceId: (video: string | undefined) => void;
}> = ({
  viewAudioDeviceId,
  viewVideoDeviceId,
  audioInputs,
  videoInputs,
  setAudioDeviceId,
  setVideoDeviceId,
}: {
  viewAudioDeviceId: string | undefined;
  viewVideoDeviceId: string | undefined;
  audioInputs: DeviceType[];
  videoInputs: DeviceType[];
  setAudioDeviceId: (audio: string | undefined) => void;
  setVideoDeviceId: (video: string | undefined) => void;
}) => {
  const [isUseCamera, setUseCamera] = useState<boolean>(false);
  const [isUseMic, setUseMic] = useState<boolean>(false);
  const [cameraHover, setCameraHover] = useState<boolean>(false);
  const [micHover, setMicHover] = useState<boolean>(false);

  const cameraRef = useRef<HTMLSelectElement | null>(null);
  const micRef = useRef<HTMLSelectElement | null>(null);

  const viewVideoDevice = videoInputs.find(
    (device: DeviceType) =>
      viewVideoDeviceId && device.deviceId === viewVideoDeviceId
  );
  const viewAudioDevice = audioInputs.find(
    (device: DeviceType) =>
      viewAudioDeviceId && device.deviceId === viewAudioDeviceId
  );

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        title={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Typography sx={{ position: "relative" }}>
              {"デバイス設定"}
              <Tooltip
                title="接続相手と通信を行う際に利用するカメラとマイクを選択できます。"
                placement="top"
              >
                <QuestionCircleOutlined style={{ marginLeft: "10px" }} />
              </Tooltip>
            </Typography>
            <Typography
              style={{
                color: "#EB1438",
                fontSize: "12px",
                margin: "8px 0",
              }}
            >
              {"※接続前に必ず設定してください"}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Box>
          <Stack>
            <Typography style={{ margin: "0 0 8px" }}>
              <VideoCameraOutlined style={{ fontSize: "16px" }} />
              <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                カメラ
              </span>
            </Typography>
            <InputLabel style={{ fontSize: "14px" }}>
              {viewVideoDevice?.label}
            </InputLabel>
            <Stack justifyContent="space-between" sx={{ mt: "10px" }}>
              <Grid container sx={{ ml: "0px" }}>
                <SelectStyled
                  onChange={(e: any) => {
                    setVideoDeviceId(e.target.value);
                  }}
                  defaultChecked={isUseCamera}
                  ref={cameraRef}
                  hover={cameraHover}
                  onMouseEnter={() => {
                    setCameraHover(true);
                  }}
                  onMouseLeave={() => setCameraHover(false)}
                >
                  {videoInputs.map((device: any, index: number) => (
                    <option value={device.deviceId} key={index}>
                      {device.label}
                    </option>
                  ))}
                </SelectStyled>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "8px 0 0",
                  }}
                >
                  <input
                    onChange={() => {
                      setUseCamera(!isUseCamera);
                      setVideoDeviceId(
                        !isUseCamera ? "none" : cameraRef.current?.value
                      );
                    }}
                    width="15px"
                    type="checkbox"
                  />
                  <InputLabel style={{ fontSize: "14px", margin: "0 0 0 8px" }}>
                    使用しない
                  </InputLabel>
                </Box>
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Typography style={{ margin: "0 0 8px" }}>
              <AudioOutlined style={{ fontSize: "16px" }} />
              <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                マイク
              </span>
            </Typography>
            <InputLabel style={{ fontSize: "14px" }}>
              {viewAudioDevice?.label}
            </InputLabel>
            <Stack justifyContent="space-between" sx={{ mt: "10px" }}>
              <Grid container>
                <SelectStyled
                  onChange={(e: any) => {
                    setAudioDeviceId(e.target.value);
                  }}
                  ref={micRef}
                  hover={micHover}
                  onMouseEnter={() => {
                    setMicHover(true);
                  }}
                  onMouseLeave={() => setMicHover(false)}
                >
                  {audioInputs.map((device: DeviceType, index: number) => (
                    <option value={device.deviceId} key={index}>
                      {device.label}
                    </option>
                  ))}
                </SelectStyled>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "8px 0 0",
                  }}
                >
                  <input
                    width="15px"
                    type="checkbox"
                    onChange={() => {
                      setUseMic(!isUseMic);
                      setAudioDeviceId(
                        !isUseMic ? "none" : micRef.current?.value
                      );
                    }}
                  />
                  <InputLabel style={{ fontSize: "14px", margin: "0 0 0 8px" }}>
                    使用しない
                  </InputLabel>
                </Box>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
